export enum TOOLS {
  RECTANGLE = 'RECTANGLE',
  LINE = 'LINE',
  TAG = 'TAG',
  SELECT = 'SELECT',
  ZOOM_IN = 'ZOOM_IN',
  ZOOM_OUT = 'ZOOM_OUT',
  ZOOM_FIT = 'ZOOM_FIT',
  DEFAULT = 'DEFAULT',
  ROTATE = 'ROTATE',
}
