import { imageApi, pdfApi } from '@lib/apis'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchPdfList = createAsyncThunk('schemes/fetchPdfList', async (_, { rejectWithValue }) => {
	try {
		const res = await pdfApi.getAllPdfDrawingFilesApiPdfDrawingFileGet()

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})

export const fetchImageList = createAsyncThunk('schemes/fetchImageList', async (_, { rejectWithValue }) => {
	try {
		const res = await imageApi.getAllImageDrawingFilesApiImageDrawingFileGet()

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})

export const getPDFsByName = createAsyncThunk('schemes/getPdfsByName', async (name: string, { rejectWithValue }) => {
	try {
		const res = await pdfApi.searchPdfDrawingFileApiPdfDrawingFileSearchGet(name)

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})

export const getImagesByName = createAsyncThunk(
	'schemes/getImagesByName',
	async (name: string, { rejectWithValue }) => {
		try {
			const res = await imageApi.searchImageDrawingFileApiImageDrawingFileSearchGet(name)

			return res.data
		} catch (error) {
			rejectWithValue(error)
		}
	}
)
