import { notification } from 'antd'
import {
	ConnectionApiFactory,
	ElementsApiFactory,
	ElementTypesApiFactory,
	FileApiFactory,
	ImageDrawingFileApiFactory,
	PdfDrawingFileApiFactory,
  ProjectsApiFactory,
	ShapeApiFactory,
	ShapeTagApiFactory,
} from '../generated/backend'
import axios from 'axios'

export const shapeApi = ShapeApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const tagApi = ShapeTagApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const connectionApi = ConnectionApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const imageApi = ImageDrawingFileApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const pdfApi = PdfDrawingFileApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const elementsApi = ElementsApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const elementTypesApi = ElementTypesApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const fileApi = FileApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const projectsApi = ProjectsApiFactory({
  isJsonMime(mime: string): boolean {
    return false
  },
  basePath: window.location.origin,
})

axios.interceptors.request.use(
	config => {
		config.withCredentials = true
		config.headers['tenant-id'] = localStorage.getItem('currentProject') || 'test'

		return config
	},
	async error => await Promise.reject(error)
)

axios.interceptors.response.use(
	response => response,
	error => {
		if (error.response && error.response.status === 401) {
			window.location.href = '/login'
		}
		if (error.response && error.response.status === 403) {
			notification.error({
				message: 'Нет доступа',
				description: 'Пожалуйста, обратитесь к администратору.',
				duration: 3,
				key: '403',
			})
		}

		return Promise.reject(error)
	}
)
