import { elementApi } from '@api/api-wrapper'
import { ElementDtoRequest } from '@lib/src/generated/backend'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchElements = createAsyncThunk('element/fetchElements', async (_, { rejectWithValue }) => {
	try {
		const response = await elementApi.getAllElementsApiElementsGet()

		return response.data
	} catch (error) {
		rejectWithValue(error)
	}
})

export const createElement = createAsyncThunk(
	'element/createElement',
	async (data: ElementDtoRequest, { rejectWithValue }) => {
		try {
			const response = await elementApi.createElementApiElementsPost(data)
			return response.data
		} catch (error) {
			rejectWithValue(error)
		}
	}
)

export const updateElement = createAsyncThunk(
	'element/updateElement',
	async ({ id, data }: { id: string; data: ElementDtoRequest }, { rejectWithValue }) => {
		try {
			const response = await elementApi.updateElementApiElementsElementIdPut(id, data)
			return response.data
		} catch (error) {
			rejectWithValue(error)
		}
	}
)

export const deleteElement = createAsyncThunk('element/deleteElement', async (id: string, { rejectWithValue }) => {
	try {
		await elementApi.deleteElementApiElementsElementIdDelete(id)
		return id
	} catch (error) {
		rejectWithValue(error)
	}
})
