import { projectsApi } from '../../api/api-wrapper'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const addNewProject = createAsyncThunk('projects/addNewProject', async (name: string, { rejectWithValue }) => {
	try {
		const res = await projectsApi.createProjectApiProjectsPost(name)

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})

export const getAllProjects = createAsyncThunk('projects/getAllProjects', async (_, { rejectWithValue }) => {
	try {
		const res = await projectsApi.getAllProjectsApiProjectsGet()

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})

export const deleteProject = createAsyncThunk('projects/deleteProject', async (id: string, { rejectWithValue }) => {
	try {
		await projectsApi.deleteProjectApiProjectsProjectIdDelete(id)

		return id
	} catch (error) {
		rejectWithValue(error)
	}
})
