import { message, notification } from 'antd'
import { useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { ApplicationMode } from '../../types'
import { setIsUIBlocked, useAppDispatch, useAppSelector } from '../store'

interface Message {
	status: 'locked' | 'active'
	locked_by: string
}

interface WebSocketProps {
	readyState: ReadyState
	lastJsonMessage: Message
}

export const useBlockUI = (applicationMode: ApplicationMode, imageId: string) => {
	if (applicationMode !== ApplicationMode.DEMO_APP || !imageId) return false

	const { lastJsonMessage, readyState }: WebSocketProps = useWebSocket(`/api/imageDrawingFile/${imageId}/ws`, {
		queryParams: { tenant_id: localStorage.getItem('currentProject') ?? 'test' },
	})

	const dispatch = useAppDispatch()

	useEffect(() => {
		const handleMessage = (messageFromWebSocket: Message): void => {
			notification.open({
				duration: 0,
				type: 'warning',
				message: 'Изображение недоступно',
				description: `Редактирует ${messageFromWebSocket.locked_by}`,
				key: 'notificationWarning',
				closeIcon: null,
			})
		}

		if (lastJsonMessage?.status === 'locked') {
			dispatch(setIsUIBlocked(true))
			handleMessage(lastJsonMessage)
		} else if (lastJsonMessage?.status === 'active') {
			dispatch(setIsUIBlocked(false))
			notification.destroy('notificationWarning')
		}

		return () => {
			notification.destroy('notificationWarning')
		}
	}, [lastJsonMessage, readyState])
}
