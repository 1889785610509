import type React from 'react'
import { useCallback, useEffect, useRef } from 'react'
import { type Stage } from 'react-konva'
import {
	getAppData,
	setContextMenuPosition,
	setSelectedShapes,
	setStageAttrs,
	setTagDraft,
	useAppDispatch,
	useAppSelector,
} from '../../../store'
import type Konva from 'konva'
import { TOOLS } from '../../header/tools/consts'
import { drawLine, finishDrawLine, startDrawLine } from './drawLine/drawLine'
import { setLabelPosition, type IShapeDraft } from '../../../store/slice/drawSlice'
import { drawRect, finishDrawRect, startDrawRect } from './drawRectangle/drawRect'
import { finishSelectArea, selectingArea, startSelectArea } from './selection/selectArea'
import { ApplicationMode, ZOOM_STEP } from '../../../../consts'
import { createTag } from './drawTag'
import { shapeApi } from '../../../api/api-wrapper'

export interface Selection {
	visible: boolean
	x1: number
	x2: number
	y1: number
	y2: number
}

type UseDraw = (stage: React.ElementRef<typeof Stage> | null) => {
	onMouseDown: (e: Konva.KonvaEventObject<MouseEvent>) => void
	onMouseMove: (e: Konva.KonvaEventObject<MouseEvent>, shapeDraft: IShapeDraft | null) => void
	onMouseUp: (e: Konva.KonvaEventObject<MouseEvent>, shapeDraft: IShapeDraft | null) => void
	onClick: (e: Konva.KonvaEventObject<MouseEvent>) => void
}

const LEFT_BUTTON = 0

export const useDraw: UseDraw = stage => {
	const dispatch = useAppDispatch()
	const selectedTool = useAppSelector(state => state.draw.stage.selectedTool)
	const selectedShapes = useAppSelector(state => state.draw.stage.selectedShapes)
	const minimalZoom = useAppSelector(state => state.draw.stage.minimalZoom)
	const applicationMode = useAppSelector(state => state.draw.applicationMode)
	const imageId = useAppSelector(state => state.draw.image.id)

	const selection = useRef<Selection>({
		visible: false,
		x1: 0,
		y1: 0,
		x2: 0,
		y2: 0,
	})

	useEffect(() => {
		const showLabel = (e: Konva.KonvaEventObject<MouseEvent>): void => {
			e.evt.preventDefault()

			const stage = e.target.getStage()
			const transformer = stage?.findOne('.transformer')

			if (stage && transformer?.absolutePosition().x) {
				const position = {
					x: stage.container().offsetLeft + transformer.absolutePosition().x,
					y: stage.container().offsetTop + transformer.absolutePosition().y + 15,
				}
				dispatch(setLabelPosition(position))
			}
		}

		const showContextMenu = (e: Konva.KonvaEventObject<MouseEvent>): void => {
			e.evt.preventDefault()
			const stage = e.target.getStage()
			const transformer = stage?.findOne('.transformer')
			if (stage && transformer?.absolutePosition().x) {
				const position = {
					x: stage.container().offsetLeft + transformer.absolutePosition().x,
					y:
						applicationMode === ApplicationMode.DEMO_APP
							? stage.container().offsetTop + transformer.absolutePosition().y + transformer.height()
							: stage.container().offsetTop + transformer.absolutePosition().y,
				}
				dispatch(setContextMenuPosition(position))
				dispatch(setLabelPosition(null))
			}
		}

		const onWheel = (e: Konva.KonvaEventObject<WheelEvent>): void => {
			e.evt.preventDefault()
			const oldScale = stage?.scale()
			const newScale = stage?.scale()
			const pointerPosition = stage?.getPointerPosition()

			if (stage && oldScale && newScale && pointerPosition) {
				const pointForZoom = {
					x: pointerPosition.x / oldScale.x - stage.x() / oldScale.x,
					y: pointerPosition.y / oldScale.x - stage.y() / oldScale.x,
				}

				if (e.evt.deltaY > 0) {
					newScale.x =
						oldScale.x - Math.abs(ZOOM_STEP * e.evt.deltaY) > 0
							? oldScale.x - Math.abs(ZOOM_STEP * e.evt.deltaY)
							: oldScale.x
					newScale.y =
						oldScale.y - Math.abs(ZOOM_STEP * e.evt.deltaY) > 0
							? oldScale.y - Math.abs(ZOOM_STEP * e.evt.deltaY)
							: oldScale.y
				} else {
					newScale.x = oldScale.x + Math.abs(ZOOM_STEP * e.evt.deltaY)
					newScale.y = oldScale.y + Math.abs(ZOOM_STEP * e.evt.deltaY)
				}

				if (e.evt.ctrlKey) {
					if (e.evt.deltaY > 0) {
						newScale.x =
							oldScale.x - Math.abs(ZOOM_STEP * e.evt.deltaY * 10) > 0
								? oldScale.x - Math.abs(ZOOM_STEP * e.evt.deltaY * 10)
								: oldScale.x
						newScale.y =
							oldScale.y - Math.abs(ZOOM_STEP * e.evt.deltaY * 10) > 0
								? oldScale.y - Math.abs(ZOOM_STEP * e.evt.deltaY * 10)
								: oldScale.y
					} else {
						newScale.x = oldScale.x + Math.abs(ZOOM_STEP * e.evt.deltaY * 10)
						newScale.y = oldScale.y + Math.abs(ZOOM_STEP * e.evt.deltaY * 10)
					}
				}
				const position = {
					x: -(pointForZoom.x - pointerPosition.x / newScale.x) * newScale.x,
					y: -(pointForZoom.y - pointerPosition.y / newScale.y) * newScale.y,
				}

				if (newScale.x > minimalZoom.x) {
					dispatch(setLabelPosition(null))
					dispatch(setContextMenuPosition(null))
					stage.position(position)
					stage.scale(newScale)
					stage.batchDraw()
					// dispatch(
					// 	setStageAttrs({
					// 		position,
					// 		scale: newScale,
					// 	})
					// )
				}
			}
		}

		const onDragStart = () => {
			dispatch(setLabelPosition(null))
			dispatch(setContextMenuPosition(null))
		}

		if (stage) {
			stage.on('wheel', onWheel)
			stage.on('contextmenu', showContextMenu)
			stage.on('click', showLabel)
			stage.on('dragstart', onDragStart)

			if (selectedTool === TOOLS.DEFAULT) {
				stage.setAttr('draggable', true)
			} else {
				stage.setAttr('draggable', false)
			}

			return () => {
				stage.off('wheel', onWheel)
				stage.off('click', showLabel)
				stage.off('dragstart', onDragStart)
				stage.off('contextmenu', showContextMenu)
			}
		}
	}, [stage, selectedTool, minimalZoom])

	useEffect(() => {
		const onDeleteKeyDown = async (e: KeyboardEvent): Promise<void> => {
			if (selectedShapes?.length && e.key === 'Delete') {
				await Promise.all(
					selectedShapes.map(shape => {
						return shapeApi.deleteShapeApiShapeShapeIdDelete(shape.id)
					})
				)
				await dispatch(getAppData())
				dispatch(setSelectedShapes([]))
			}
		}

		addEventListener('keydown', onDeleteKeyDown)
		return () => {
			removeEventListener('keydown', onDeleteKeyDown)
		}
	}, [selectedShapes])

	const onMouseDown = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent>) => {
			if (e.evt.button === LEFT_BUTTON) {
				if (selectedTool === TOOLS.LINE && imageId) {
					dispatch(startDrawLine(e, imageId))
				}
				if (selectedTool === TOOLS.RECTANGLE && imageId) {
					dispatch(startDrawRect(e, imageId))
				}
				if (selectedTool === TOOLS.SELECT && stage) {
					startSelectArea(e, stage, selection)
				}
			}
		},
		[selectedTool, stage]
	)

	const onMouseMove = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent>, shapeDraft: IShapeDraft | null) => {
			if (selectedTool === TOOLS.LINE && shapeDraft?.shape_type && shapeDraft.isDrawing) {
				dispatch(drawLine(e, shapeDraft))
			}
			if (selectedTool === TOOLS.RECTANGLE && shapeDraft?.shape_type && shapeDraft.isDrawing) {
				dispatch(drawRect(e, shapeDraft))
			}
			if (selectedTool === TOOLS.SELECT && stage) {
				selectingArea(e, stage, selection)
			}
		},
		[selectedTool, stage]
	)

	const onMouseUp = useCallback(
		async (e: Konva.KonvaEventObject<MouseEvent>, shapeDraft: IShapeDraft | null) => {
			if (e.evt.button === LEFT_BUTTON && imageId && applicationMode) {
				if (selectedTool === TOOLS.LINE && shapeDraft?.shape_type) {
					await dispatch(finishDrawLine(e, shapeDraft))
					await dispatch(getAppData())
				}
				if (selectedTool === TOOLS.RECTANGLE && shapeDraft?.shape_type) {
					await dispatch(finishDrawRect(e, shapeDraft))
					await dispatch(getAppData())
				}
				if (selectedTool === TOOLS.SELECT && stage) {
					dispatch(finishSelectArea(stage, selection))
				}
			}
		},
		[selectedTool, stage]
	)

	const onClick = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent>) => {
			if (e.evt.button !== 0) {
				return
			}
			if (selectedTool === TOOLS.TAG) {
				dispatch(createTag(e))
			}

			if (e.target === stage) {
				dispatch(setSelectedShapes([]))
				dispatch(setContextMenuPosition(null))
				dispatch(setLabelPosition(null))
				dispatch(
					setTagDraft({
						shapeId: null,
						position: null,
						shapePosition: null,
					})
				)
			}
		},
		[selectedTool, stage]
	)

	return {
		onMouseDown,
		onMouseMove,
		onMouseUp,
		onClick,
	}
}
