import { ImageDrawingFileDto, PdfDrawingFileDto } from '@lib/src/generated/backend'
import axios from 'axios'

export const downloadImage = async (name: ImageDrawingFileDto['filename'], id: ImageDrawingFileDto['id']) => {
	try {
		const res = await axios.get(`/api/file/${id}/content`, {
			responseType: 'blob',
		})

		const href = URL.createObjectURL(res.data)
		const link = document.createElement('a')
		link.href = href
		link.setAttribute('download', name)
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		URL.revokeObjectURL(href)
	} catch (error) {
		console.error(error)
	}
}

export const downloadPDF = async (name: PdfDrawingFileDto['filename'], id: PdfDrawingFileDto['id']) => {
	try {
		const res = await axios.get(`/api/pdfDrawingFile/pdf_with_labels?pdf_id=${id}`, {
			responseType: 'blob',
		})

		const href = URL.createObjectURL(res.data)
		const link = document.createElement('a')
		link.href = href
		link.setAttribute('download', name)
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		URL.revokeObjectURL(href)
	} catch (error) {
		console.error(error)
	}
}
