import { ProjectDto } from '@lib/src/generated/backend'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addNewProject, deleteProject, getAllProjects } from './projects.actions'

export interface ProjectsState {
	projectsList: ProjectDto[]
	currentProject: ProjectDto
	status: 'loading' | 'failed' | 'success'
}

const initialState: ProjectsState = {
	projectsList: [],
	currentProject: {
		name: '',
		id: '',
		db_schema: '',
	},
	status: 'loading',
}

export const projectSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		setProjectAsCurrent: (state, action) => {
			state.currentProject = action.payload
			localStorage.setItem('currentProject', action.payload.db_schema)
			localStorage.setItem('currentProjectName', action.payload.name)
		},
	},
	extraReducers: builder => {
		// create new
		builder.addCase(addNewProject.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(addNewProject.fulfilled, (state, action: PayloadAction<ProjectDto>) => {
			state.projectsList = [...state.projectsList, action.payload]
			state.currentProject = { ...action.payload }
			state.status = 'success'
		})
		builder.addCase(addNewProject.rejected, state => {
			state.status = 'failed'
		})

		// get all
		builder.addCase(getAllProjects.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(getAllProjects.fulfilled, (state, action) => {
			state.projectsList = action.payload!
			state.status = 'success'
		})
		builder.addCase(getAllProjects.rejected, (state, action) => {
			state.status = 'failed'
		})

		// delete project
		builder.addCase(deleteProject.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(deleteProject.fulfilled, (state, action) => {
			state.projectsList = state.projectsList.filter(project => project.id !== action.payload)
			state.currentProject = { name: '', id: '', db_schema: '' }
			localStorage.removeItem('currentProject')
			localStorage.removeItem('currentProjectName')
			state.status = 'success'
		})
		builder.addCase(deleteProject.rejected, state => {
			state.status = 'failed'
		})
	},
})
export const { setProjectAsCurrent } = projectSlice.actions

export const {} = projectSlice.actions

export default projectSlice.reducer
