import { loginApi, projectsApi } from '@api/api-wrapper'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getMe = createAsyncThunk('users/getMe', async (_, { rejectWithValue }) => {
	try {
		const res = await loginApi.getMeApiUsersMeGet()

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})
export const getUserRoles = createAsyncThunk('users/getUserRoles', async (_, { rejectWithValue }) => {
	try {
		const res = await loginApi.getMeApiUsersMeGet()

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})
export const getAllUsers = createAsyncThunk('users/getAllUsers', async (_, { rejectWithValue }) => {
	try {
		const res = await loginApi.getAllUsersApiUsersGet()

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})

export const getUsersForProject = createAsyncThunk(
	'users/getUsersForProject',
	async (project: string, { rejectWithValue }) => {
		try {
			const res = await projectsApi.getUsersByProjectApiProjectsUsersProjectProjectIdGet(project)

			return res.data
		} catch (error) {
			rejectWithValue(error)
		}
	}
)

export const attachUserToProject = createAsyncThunk(
	'users/attachUserToProject',
	async ({ projectId, userId }: { projectId: string; userId: string }, { rejectWithValue }) => {
		try {
			await projectsApi.attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost(projectId, userId)

			return userId
		} catch (error) {
			rejectWithValue(error)
		}
	}
)

export const detachUserFromProject = createAsyncThunk(
	'users/detachUserFromProject',
	async ({ projectId, userId }: { projectId: string; userId: string }, { rejectWithValue }) => {
		try {
			await projectsApi.detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost(projectId, userId)

			return userId
		} catch (error) {
			rejectWithValue(error)
		}
	}
)
