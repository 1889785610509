import { useEffect, useRef } from 'react'

export const useOutsideClick = <TypeOfElement extends HTMLElement>(callback: () => void) => {
	const ref = useRef<TypeOfElement>(null)

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				callback()
			}
		}

		document.addEventListener('click', handleClickOutside)

		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [callback])

	return ref
}
