import { ThemeConfig } from 'antd'

export const dropdownTheme: ThemeConfig = {
	components: {
		Dropdown: {
			colorBgElevated: 'rgba(33, 82, 148, 1)',
			borderRadiusLG: 2,
			paddingBlock: 0,
			controlPaddingHorizontal: 0,
			paddingXXS: 0,
			marginXXS: 0,
			colorSplit: 'rgba(217, 217, 217, 1)',
			zIndexPopup: 1,
		},
		Input: {
			borderRadius: 2,
			colorBgContainer: 'rgba(33, 82, 148, 1)',
			colorText: 'rgba(255, 255, 255, 0.85)',
			colorTextPlaceholder: 'rgba(88, 122, 169, 1)',
			colorBorder: 'rgba(88, 122, 169, 1)',
			colorPrimaryBgHover: 'rgba(88, 122, 169, 1)',
			fontSize: 14,
			colorPrimaryHover: 'rgba(88, 122, 169, 1)',
			activeShadow: 'none',
			activeBorderColor: 'rgba(88, 122, 169, 1)',
			colorTextDescription: 'rgba(255, 255, 255, 1)',
		},
		Button: {
			borderRadius: 2,
			colorPrimary: 'rgba(33, 82, 148, 1)',
			colorPrimaryHover: 'rgba(33, 82, 148, 1)',
			colorPrimaryActive: 'rgba(33, 82, 148, 1)',
			defaultActiveBg: 'rgba(88, 122, 169, 1)',
			defaultActiveBorderColor: 'rgba(88, 122, 169, 1)',
			defaultBg: 'rgba(33, 82, 148, 1)',
			defaultBorderColor: 'rgba(88, 122, 169, 1)',
			defaultHoverBg: 'rgba(33, 82, 148, 1)',
			defaultHoverBorderColor: 'rgba(88, 122, 169, 1)',
			defaultColor: 'rgba(255, 255, 255, 1)',
		},
	},
}
