/* tslint:disable */
/* eslint-disable */
/**
 * Pandid Backend API
 * This is a very fancy project, with auto docs for the API and everything
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AuthType = {
    Native: 'NATIVE',
    Ldap: 'LDAP',
    Oauth: 'OAUTH'
} as const;

export type AuthType = typeof AuthType[keyof typeof AuthType];


/**
 * 
 * @export
 * @interface ChunkUploadData
 */
export interface ChunkUploadData {
    /**
     * 
     * @type {string}
     * @memberof ChunkUploadData
     */
    'e_tag': string;
    /**
     * 
     * @type {number}
     * @memberof ChunkUploadData
     */
    'part_number': number;
    /**
     * 
     * @type {number}
     * @memberof ChunkUploadData
     */
    'chunk_size': number;
}
/**
 * 
 * @export
 * @interface ConnectionDto
 */
export interface ConnectionDto {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDto
     */
    'color': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionDto
     */
    'shapes_id'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ConnectionDtoRequest
 */
export interface ConnectionDtoRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDtoRequest
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface DiagramComponent
 */
export interface DiagramComponent {
    /**
     * 
     * @type {string}
     * @memberof DiagramComponent
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiagramComponent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DiagramComponent
     */
    'color': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiagramComponent
     */
    'shape_ids': Array<string>;
    /**
     * 
     * @type {DiagramComponentType}
     * @memberof DiagramComponent
     */
    'diagram_component_type': DiagramComponentType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DiagramComponentType = {
    Element: 'ELEMENT',
    RbiCorrosionLoop: 'RBI_CORROSION_LOOP',
    RbiComponent: 'RBI_COMPONENT',
    RbiEquipment: 'RBI_EQUIPMENT',
    HazopNode: 'HAZOP_NODE',
    HazopEquipment: 'HAZOP_EQUIPMENT'
} as const;

export type DiagramComponentType = typeof DiagramComponentType[keyof typeof DiagramComponentType];


/**
 * 
 * @export
 * @interface ElementDto
 */
export interface ElementDto {
    /**
     * 
     * @type {string}
     * @memberof ElementDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ElementDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ElementDto
     */
    'color': string;
    /**
     * 
     * @type {ElementTypeDto}
     * @memberof ElementDto
     */
    'type': ElementTypeDto;
}
/**
 * 
 * @export
 * @interface ElementDtoRequest
 */
export interface ElementDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof ElementDtoRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ElementDtoRequest
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof ElementDtoRequest
     */
    'type_id': string;
}
/**
 * 
 * @export
 * @interface ElementTypeDto
 */
export interface ElementTypeDto {
    /**
     * 
     * @type {string}
     * @memberof ElementTypeDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ElementTypeDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ElementTypeDtoRequest
 */
export interface ElementTypeDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof ElementTypeDtoRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'filename': string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ImageDrawingFileDto
 */
export interface ImageDrawingFileDto {
    /**
     * 
     * @type {string}
     * @memberof ImageDrawingFileDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageDrawingFileDto
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof ImageDrawingFileDto
     */
    'filename': string;
    /**
     * 
     * @type {number}
     * @memberof ImageDrawingFileDto
     */
    'dpi': number;
    /**
     * 
     * @type {string}
     * @memberof ImageDrawingFileDto
     */
    'parent_pdf_id'?: string | null;
    /**
     * 
     * @type {PdfDrawingFileDto}
     * @memberof ImageDrawingFileDto
     */
    'parent_pdf'?: PdfDrawingFileDto | null;
    /**
     * 
     * @type {number}
     * @memberof ImageDrawingFileDto
     */
    'image_number'?: number;
}
/**
 * 
 * @export
 * @interface InfoDto
 */
export interface InfoDto {
    /**
     * 
     * @type {string}
     * @memberof InfoDto
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof InfoDto
     */
    'build_time': string;
}
/**
 * 
 * @export
 * @interface MultipartUploadData
 */
export interface MultipartUploadData {
    /**
     * 
     * @type {string}
     * @memberof MultipartUploadData
     */
    'upload_id': string;
    /**
     * 
     * @type {string}
     * @memberof MultipartUploadData
     */
    'file_key': string;
}
/**
 * 
 * @export
 * @interface PdfDrawingFileDto
 */
export interface PdfDrawingFileDto {
    /**
     * 
     * @type {string}
     * @memberof PdfDrawingFileDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PdfDrawingFileDto
     */
    'is_splitting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PdfDrawingFileDto
     */
    'split_success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PdfDrawingFileDto
     */
    'page_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PdfDrawingFileDto
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof PdfDrawingFileDto
     */
    'image_drawing_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PdfDrawingFileDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'db_schema': string;
}
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface RoleDtoRequest
 */
export interface RoleDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleDtoRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDtoRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ShapeDto
 */
export interface ShapeDto {
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {ShapeType}
     * @memberof ShapeDto
     */
    'shape_type': ShapeType;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShapeDto
     */
    'points'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'y'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'image_drawing_id': string;
    /**
     * 
     * @type {Array<ShapeTagDto>}
     * @memberof ShapeDto
     */
    'tags'?: Array<ShapeTagDto>;
}


/**
 * 
 * @export
 * @interface ShapeDtoRequest
 */
export interface ShapeDtoRequest {
    /**
     * 
     * @type {ShapeType}
     * @memberof ShapeDtoRequest
     */
    'shape_type': ShapeType;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShapeDtoRequest
     */
    'points'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'y'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeDtoRequest
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeDtoRequest
     */
    'image_drawing_id': string;
}


/**
 * 
 * @export
 * @interface ShapeDtoResponse
 */
export interface ShapeDtoResponse {
    /**
     * 
     * @type {Array<ShapeDto>}
     * @memberof ShapeDtoResponse
     */
    'shapes': Array<ShapeDto>;
    /**
     * 
     * @type {Array<DiagramComponent>}
     * @memberof ShapeDtoResponse
     */
    'diagramComponent': Array<DiagramComponent>;
    /**
     * 
     * @type {Array<ConnectionDto>}
     * @memberof ShapeDtoResponse
     */
    'connections': Array<ConnectionDto>;
}
/**
 * 
 * @export
 * @interface ShapeTagDto
 */
export interface ShapeTagDto {
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDto
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDto
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDto
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'shape_id': string;
}
/**
 * 
 * @export
 * @interface ShapeTagDtoRequest
 */
export interface ShapeTagDtoRequest {
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDtoRequest
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDtoRequest
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDtoRequest
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'shape_id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ShapeType = {
    Rectangle: 'RECTANGLE',
    Line: 'LINE',
    Polygon: 'POLYGON'
} as const;

export type ShapeType = typeof ShapeType[keyof typeof ShapeType];


/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'login': string;
    /**
     * 
     * @type {AuthType}
     * @memberof UserDto
     */
    'auth_type': AuthType;
}


/**
 * 
 * @export
 * @interface UserDtoRequest
 */
export interface UserDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof UserDtoRequest
     */
    'login': string;
    /**
     * 
     * @type {string}
     * @memberof UserDtoRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * ConnectionApi - axios parameter creator
 * @export
 */
export const ConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Connection
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnectionApiConnectionPost: async (connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionDtoRequest' is not null or undefined
            assertParamExists('createConnectionApiConnectionPost', 'connectionDtoRequest', connectionDtoRequest)
            const localVarPath = `/api/connection/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnectionApiConnectionConnectionIdDelete: async (connectionId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('deleteConnectionApiConnectionConnectionIdDelete', 'connectionId', connectionId)
            const localVarPath = `/api/connection/{connection_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Connections
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConnectionsApiConnectionGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/connection/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionApiConnectionConnectionIdGet: async (connectionId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('getConnectionApiConnectionConnectionIdGet', 'connectionId', connectionId)
            const localVarPath = `/api/connection/{connection_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Connection
         * @param {string} connectionId 
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionApiConnectionConnectionIdPut: async (connectionId: string, connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('updateConnectionApiConnectionConnectionIdPut', 'connectionId', connectionId)
            // verify required parameter 'connectionDtoRequest' is not null or undefined
            assertParamExists('updateConnectionApiConnectionConnectionIdPut', 'connectionDtoRequest', connectionDtoRequest)
            const localVarPath = `/api/connection/{connection_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionApi - functional programming interface
 * @export
 */
export const ConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Connection
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConnectionApiConnectionPost(connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConnectionApiConnectionPost(connectionDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.createConnectionApiConnectionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConnectionApiConnectionConnectionIdDelete(connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConnectionApiConnectionConnectionIdDelete(connectionId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.deleteConnectionApiConnectionConnectionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Connections
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConnectionsApiConnectionGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConnectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConnectionsApiConnectionGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.getAllConnectionsApiConnectionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionApiConnectionConnectionIdGet(connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionApiConnectionConnectionIdGet(connectionId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.getConnectionApiConnectionConnectionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Connection
         * @param {string} connectionId 
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionApiConnectionConnectionIdPut(connectionId: string, connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionApiConnectionConnectionIdPut(connectionId, connectionDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.updateConnectionApiConnectionConnectionIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConnectionApi - factory interface
 * @export
 */
export const ConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Connection
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnectionApiConnectionPost(connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: any): AxiosPromise<ConnectionDto> {
            return localVarFp.createConnectionApiConnectionPost(connectionDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnectionApiConnectionConnectionIdDelete(connectionId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteConnectionApiConnectionConnectionIdDelete(connectionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Connections
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConnectionsApiConnectionGet(tenantId?: string, options?: any): AxiosPromise<Array<ConnectionDto>> {
            return localVarFp.getAllConnectionsApiConnectionGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionApiConnectionConnectionIdGet(connectionId: string, tenantId?: string, options?: any): AxiosPromise<ConnectionDto> {
            return localVarFp.getConnectionApiConnectionConnectionIdGet(connectionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Connection
         * @param {string} connectionId 
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionApiConnectionConnectionIdPut(connectionId: string, connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: any): AxiosPromise<ConnectionDto> {
            return localVarFp.updateConnectionApiConnectionConnectionIdPut(connectionId, connectionDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectionApi - object-oriented interface
 * @export
 * @class ConnectionApi
 * @extends {BaseAPI}
 */
export class ConnectionApi extends BaseAPI {
    /**
     * 
     * @summary Create Connection
     * @param {ConnectionDtoRequest} connectionDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public createConnectionApiConnectionPost(connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).createConnectionApiConnectionPost(connectionDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Connection
     * @param {string} connectionId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public deleteConnectionApiConnectionConnectionIdDelete(connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).deleteConnectionApiConnectionConnectionIdDelete(connectionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Connections
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public getAllConnectionsApiConnectionGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).getAllConnectionsApiConnectionGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Connection
     * @param {string} connectionId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public getConnectionApiConnectionConnectionIdGet(connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).getConnectionApiConnectionConnectionIdGet(connectionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Connection
     * @param {string} connectionId 
     * @param {ConnectionDtoRequest} connectionDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public updateConnectionApiConnectionConnectionIdPut(connectionId: string, connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).updateConnectionApiConnectionConnectionIdPut(connectionId, connectionDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DcApi - axios parameter creator
 * @export
 */
export const DcApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get All Dc
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDcApiDcGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dc/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DcApi - functional programming interface
 * @export
 */
export const DcApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DcApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get All Dc
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDcApiDcGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiagramComponent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDcApiDcGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DcApi.getAllDcApiDcGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DcApi - factory interface
 * @export
 */
export const DcApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DcApiFp(configuration)
    return {
        /**
         * 
         * @summary Get All Dc
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDcApiDcGet(tenantId?: string, options?: any): AxiosPromise<Array<DiagramComponent>> {
            return localVarFp.getAllDcApiDcGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DcApi - object-oriented interface
 * @export
 * @class DcApi
 * @extends {BaseAPI}
 */
export class DcApi extends BaseAPI {
    /**
     * 
     * @summary Get All Dc
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DcApi
     */
    public getAllDcApiDcGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return DcApiFp(this.configuration).getAllDcApiDcGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ElementTypesApi - axios parameter creator
 * @export
 */
export const ElementTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Element Type
         * @param {ElementTypeDtoRequest} elementTypeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElementTypeApiElementTypesPost: async (elementTypeDtoRequest: ElementTypeDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementTypeDtoRequest' is not null or undefined
            assertParamExists('createElementTypeApiElementTypesPost', 'elementTypeDtoRequest', elementTypeDtoRequest)
            const localVarPath = `/api/element-types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(elementTypeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Element Type
         * @param {string} elementTypeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElementTypeApiElementTypesElementTypeIdDelete: async (elementTypeId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementTypeId' is not null or undefined
            assertParamExists('deleteElementTypeApiElementTypesElementTypeIdDelete', 'elementTypeId', elementTypeId)
            const localVarPath = `/api/element-types/{element_type_id}`
                .replace(`{${"element_type_id"}}`, encodeURIComponent(String(elementTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Element Types
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllElementTypesApiElementTypesGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/element-types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElementTypesApi - functional programming interface
 * @export
 */
export const ElementTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ElementTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Element Type
         * @param {ElementTypeDtoRequest} elementTypeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createElementTypeApiElementTypesPost(elementTypeDtoRequest: ElementTypeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElementTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createElementTypeApiElementTypesPost(elementTypeDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElementTypesApi.createElementTypeApiElementTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Element Type
         * @param {string} elementTypeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteElementTypeApiElementTypesElementTypeIdDelete(elementTypeId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteElementTypeApiElementTypesElementTypeIdDelete(elementTypeId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElementTypesApi.deleteElementTypeApiElementTypesElementTypeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Element Types
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllElementTypesApiElementTypesGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ElementTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllElementTypesApiElementTypesGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElementTypesApi.getAllElementTypesApiElementTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ElementTypesApi - factory interface
 * @export
 */
export const ElementTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ElementTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Element Type
         * @param {ElementTypeDtoRequest} elementTypeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElementTypeApiElementTypesPost(elementTypeDtoRequest: ElementTypeDtoRequest, tenantId?: string, options?: any): AxiosPromise<ElementTypeDto> {
            return localVarFp.createElementTypeApiElementTypesPost(elementTypeDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Element Type
         * @param {string} elementTypeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElementTypeApiElementTypesElementTypeIdDelete(elementTypeId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteElementTypeApiElementTypesElementTypeIdDelete(elementTypeId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Element Types
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllElementTypesApiElementTypesGet(tenantId?: string, options?: any): AxiosPromise<Array<ElementTypeDto>> {
            return localVarFp.getAllElementTypesApiElementTypesGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ElementTypesApi - object-oriented interface
 * @export
 * @class ElementTypesApi
 * @extends {BaseAPI}
 */
export class ElementTypesApi extends BaseAPI {
    /**
     * 
     * @summary Create Element Type
     * @param {ElementTypeDtoRequest} elementTypeDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTypesApi
     */
    public createElementTypeApiElementTypesPost(elementTypeDtoRequest: ElementTypeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ElementTypesApiFp(this.configuration).createElementTypeApiElementTypesPost(elementTypeDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Element Type
     * @param {string} elementTypeId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTypesApi
     */
    public deleteElementTypeApiElementTypesElementTypeIdDelete(elementTypeId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ElementTypesApiFp(this.configuration).deleteElementTypeApiElementTypesElementTypeIdDelete(elementTypeId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Element Types
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTypesApi
     */
    public getAllElementTypesApiElementTypesGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ElementTypesApiFp(this.configuration).getAllElementTypesApiElementTypesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ElementsApi - axios parameter creator
 * @export
 */
export const ElementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Element
         * @param {ElementDtoRequest} elementDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElementApiElementsPost: async (elementDtoRequest: ElementDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementDtoRequest' is not null or undefined
            assertParamExists('createElementApiElementsPost', 'elementDtoRequest', elementDtoRequest)
            const localVarPath = `/api/elements/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(elementDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Element
         * @param {string} elementId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElementApiElementsElementIdDelete: async (elementId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('deleteElementApiElementsElementIdDelete', 'elementId', elementId)
            const localVarPath = `/api/elements/{element_id}`
                .replace(`{${"element_id"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Elements
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllElementsApiElementsGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/elements/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Element
         * @param {string} elementId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementApiElementsElementIdGet: async (elementId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('getElementApiElementsElementIdGet', 'elementId', elementId)
            const localVarPath = `/api/elements/{element_id}`
                .replace(`{${"element_id"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Element
         * @param {string} elementId 
         * @param {ElementDtoRequest} elementDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementApiElementsElementIdPut: async (elementId: string, elementDtoRequest: ElementDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('updateElementApiElementsElementIdPut', 'elementId', elementId)
            // verify required parameter 'elementDtoRequest' is not null or undefined
            assertParamExists('updateElementApiElementsElementIdPut', 'elementDtoRequest', elementDtoRequest)
            const localVarPath = `/api/elements/{element_id}`
                .replace(`{${"element_id"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(elementDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElementsApi - functional programming interface
 * @export
 */
export const ElementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ElementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Element
         * @param {ElementDtoRequest} elementDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createElementApiElementsPost(elementDtoRequest: ElementDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createElementApiElementsPost(elementDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElementsApi.createElementApiElementsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Element
         * @param {string} elementId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteElementApiElementsElementIdDelete(elementId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteElementApiElementsElementIdDelete(elementId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElementsApi.deleteElementApiElementsElementIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Elements
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllElementsApiElementsGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ElementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllElementsApiElementsGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElementsApi.getAllElementsApiElementsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Element
         * @param {string} elementId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElementApiElementsElementIdGet(elementId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElementApiElementsElementIdGet(elementId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElementsApi.getElementApiElementsElementIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Element
         * @param {string} elementId 
         * @param {ElementDtoRequest} elementDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateElementApiElementsElementIdPut(elementId: string, elementDtoRequest: ElementDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateElementApiElementsElementIdPut(elementId, elementDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElementsApi.updateElementApiElementsElementIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ElementsApi - factory interface
 * @export
 */
export const ElementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ElementsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Element
         * @param {ElementDtoRequest} elementDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElementApiElementsPost(elementDtoRequest: ElementDtoRequest, tenantId?: string, options?: any): AxiosPromise<ElementDto> {
            return localVarFp.createElementApiElementsPost(elementDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Element
         * @param {string} elementId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElementApiElementsElementIdDelete(elementId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteElementApiElementsElementIdDelete(elementId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Elements
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllElementsApiElementsGet(tenantId?: string, options?: any): AxiosPromise<Array<ElementDto>> {
            return localVarFp.getAllElementsApiElementsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Element
         * @param {string} elementId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementApiElementsElementIdGet(elementId: string, tenantId?: string, options?: any): AxiosPromise<ElementDto> {
            return localVarFp.getElementApiElementsElementIdGet(elementId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Element
         * @param {string} elementId 
         * @param {ElementDtoRequest} elementDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateElementApiElementsElementIdPut(elementId: string, elementDtoRequest: ElementDtoRequest, tenantId?: string, options?: any): AxiosPromise<ElementDto> {
            return localVarFp.updateElementApiElementsElementIdPut(elementId, elementDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ElementsApi - object-oriented interface
 * @export
 * @class ElementsApi
 * @extends {BaseAPI}
 */
export class ElementsApi extends BaseAPI {
    /**
     * 
     * @summary Create Element
     * @param {ElementDtoRequest} elementDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementsApi
     */
    public createElementApiElementsPost(elementDtoRequest: ElementDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ElementsApiFp(this.configuration).createElementApiElementsPost(elementDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Element
     * @param {string} elementId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementsApi
     */
    public deleteElementApiElementsElementIdDelete(elementId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ElementsApiFp(this.configuration).deleteElementApiElementsElementIdDelete(elementId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Elements
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementsApi
     */
    public getAllElementsApiElementsGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ElementsApiFp(this.configuration).getAllElementsApiElementsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Element
     * @param {string} elementId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementsApi
     */
    public getElementApiElementsElementIdGet(elementId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ElementsApiFp(this.configuration).getElementApiElementsElementIdGet(elementId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Element
     * @param {string} elementId 
     * @param {ElementDtoRequest} elementDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementsApi
     */
    public updateElementApiElementsElementIdPut(elementId: string, elementDtoRequest: ElementDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ElementsApiFp(this.configuration).updateElementApiElementsElementIdPut(elementId, elementDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export Dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDatasetApiExportPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export Dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDatasetApiExportPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportDatasetApiExportPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExportApi.exportDatasetApiExportPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * 
         * @summary Export Dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDatasetApiExportPost(options?: any): AxiosPromise<any> {
            return localVarFp.exportDatasetApiExportPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * 
     * @summary Export Dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public exportDatasetApiExportPost(options?: RawAxiosRequestConfig) {
        return ExportApiFp(this.configuration).exportDatasetApiExportPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileApiFileFileIdDelete: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFileApiFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/file/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFilesApiFileGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Content
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentApiFileFileIdContentGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getContentApiFileFileIdContentGet', 'fileId', fileId)
            const localVarPath = `/api/file/{file_id}/content`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileApiFileFileIdGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getFileApiFileFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/file/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Chunk
         * @param {number} chunkNumber 
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {File} chunk 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadChunkApiFileUploadChunkPost: async (chunkNumber: number, uploadId: string, fileKey: string, chunk: File, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chunkNumber' is not null or undefined
            assertParamExists('uploadChunkApiFileUploadChunkPost', 'chunkNumber', chunkNumber)
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('uploadChunkApiFileUploadChunkPost', 'uploadId', uploadId)
            // verify required parameter 'fileKey' is not null or undefined
            assertParamExists('uploadChunkApiFileUploadChunkPost', 'fileKey', fileKey)
            // verify required parameter 'chunk' is not null or undefined
            assertParamExists('uploadChunkApiFileUploadChunkPost', 'chunk', chunk)
            const localVarPath = `/api/file/upload/chunk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication APIKeyCookie required

            if (chunkNumber !== undefined) {
                localVarQueryParameter['chunk_number'] = chunkNumber;
            }

            if (uploadId !== undefined) {
                localVarQueryParameter['upload_id'] = uploadId;
            }

            if (fileKey !== undefined) {
                localVarQueryParameter['file_key'] = fileKey;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


            if (chunk !== undefined) { 
                localVarFormParams.append('chunk', chunk as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Finish
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {string} filename 
         * @param {Array<ChunkUploadData>} chunkUploadData 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFinishApiFileUploadFinishPost: async (uploadId: string, fileKey: string, filename: string, chunkUploadData: Array<ChunkUploadData>, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('uploadFinishApiFileUploadFinishPost', 'uploadId', uploadId)
            // verify required parameter 'fileKey' is not null or undefined
            assertParamExists('uploadFinishApiFileUploadFinishPost', 'fileKey', fileKey)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('uploadFinishApiFileUploadFinishPost', 'filename', filename)
            // verify required parameter 'chunkUploadData' is not null or undefined
            assertParamExists('uploadFinishApiFileUploadFinishPost', 'chunkUploadData', chunkUploadData)
            const localVarPath = `/api/file/upload/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (uploadId !== undefined) {
                localVarQueryParameter['upload_id'] = uploadId;
            }

            if (fileKey !== undefined) {
                localVarQueryParameter['file_key'] = fileKey;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chunkUploadData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Start
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStartApiFileUploadStartPost: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/upload/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileApiFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileApiFileFileIdDelete(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.deleteFileApiFileFileIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFilesApiFileGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFilesApiFileGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.getAllFilesApiFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Content
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentApiFileFileIdContentGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentApiFileFileIdContentGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.getContentApiFileFileIdContentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileApiFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileApiFileFileIdGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.getFileApiFileFileIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Chunk
         * @param {number} chunkNumber 
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {File} chunk 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadChunkApiFileUploadChunkPost(chunkNumber: number, uploadId: string, fileKey: string, chunk: File, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChunkUploadData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadChunkApiFileUploadChunkPost(chunkNumber, uploadId, fileKey, chunk, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.uploadChunkApiFileUploadChunkPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Finish
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {string} filename 
         * @param {Array<ChunkUploadData>} chunkUploadData 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFinishApiFileUploadFinishPost(uploadId: string, fileKey: string, filename: string, chunkUploadData: Array<ChunkUploadData>, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFinishApiFileUploadFinishPost(uploadId, fileKey, filename, chunkUploadData, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.uploadFinishApiFileUploadFinishPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Start
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStartApiFileUploadStartPost(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultipartUploadData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStartApiFileUploadStartPost(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.uploadStartApiFileUploadStartPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileApiFileFileIdDelete(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteFileApiFileFileIdDelete(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFilesApiFileGet(tenantId?: string, options?: any): AxiosPromise<Array<FileDto>> {
            return localVarFp.getAllFilesApiFileGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Content
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentApiFileFileIdContentGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getContentApiFileFileIdContentGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileApiFileFileIdGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<FileDto> {
            return localVarFp.getFileApiFileFileIdGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Chunk
         * @param {number} chunkNumber 
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {File} chunk 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadChunkApiFileUploadChunkPost(chunkNumber: number, uploadId: string, fileKey: string, chunk: File, tenantId?: string, options?: any): AxiosPromise<ChunkUploadData> {
            return localVarFp.uploadChunkApiFileUploadChunkPost(chunkNumber, uploadId, fileKey, chunk, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Finish
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {string} filename 
         * @param {Array<ChunkUploadData>} chunkUploadData 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFinishApiFileUploadFinishPost(uploadId: string, fileKey: string, filename: string, chunkUploadData: Array<ChunkUploadData>, tenantId?: string, options?: any): AxiosPromise<FileDto> {
            return localVarFp.uploadFinishApiFileUploadFinishPost(uploadId, fileKey, filename, chunkUploadData, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Start
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStartApiFileUploadStartPost(tenantId?: string, options?: any): AxiosPromise<MultipartUploadData> {
            return localVarFp.uploadStartApiFileUploadStartPost(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Delete File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFileApiFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).deleteFileApiFileFileIdDelete(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Files
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getAllFilesApiFileGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).getAllFilesApiFileGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Content
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getContentApiFileFileIdContentGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).getContentApiFileFileIdContentGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFileApiFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).getFileApiFileFileIdGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Chunk
     * @param {number} chunkNumber 
     * @param {string} uploadId 
     * @param {string} fileKey 
     * @param {File} chunk 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadChunkApiFileUploadChunkPost(chunkNumber: number, uploadId: string, fileKey: string, chunk: File, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadChunkApiFileUploadChunkPost(chunkNumber, uploadId, fileKey, chunk, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Finish
     * @param {string} uploadId 
     * @param {string} fileKey 
     * @param {string} filename 
     * @param {Array<ChunkUploadData>} chunkUploadData 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadFinishApiFileUploadFinishPost(uploadId: string, fileKey: string, filename: string, chunkUploadData: Array<ChunkUploadData>, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadFinishApiFileUploadFinishPost(uploadId, fileKey, filename, chunkUploadData, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Start
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadStartApiFileUploadStartPost(tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadStartApiFileUploadStartPost(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImageDrawingFileApi - axios parameter creator
 * @export
 */
export const ImageDrawingFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageDrawingFileApiImageDrawingFileFileIdDelete: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteImageDrawingFileApiImageDrawingFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/imageDrawingFile/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Image Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImageDrawingFilesApiImageDrawingFileGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/imageDrawingFile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Image By Drawing And Number
         * @param {string} pdfId 
         * @param {number} imageNumber 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet: async (pdfId: string, imageNumber: number, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfId' is not null or undefined
            assertParamExists('getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet', 'pdfId', pdfId)
            // verify required parameter 'imageNumber' is not null or undefined
            assertParamExists('getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet', 'imageNumber', imageNumber)
            const localVarPath = `/api/imageDrawingFile/by-pdf-and-number/{pdf_id}`
                .replace(`{${"pdf_id"}}`, encodeURIComponent(String(pdfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (imageNumber !== undefined) {
                localVarQueryParameter['image_number'] = imageNumber;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Image By Drawing And Number
         * @param {string} imageId 
         * @param {number} angle 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet: async (imageId: string, angle: number, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet', 'imageId', imageId)
            // verify required parameter 'angle' is not null or undefined
            assertParamExists('getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet', 'angle', angle)
            const localVarPath = `/api/imageDrawingFile/{image_id}/rotate/`
                .replace(`{${"image_id"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (angle !== undefined) {
                localVarQueryParameter['angle'] = angle;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageDrawingFileApiImageDrawingFileFileIdGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getImageDrawingFileApiImageDrawingFileFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/imageDrawingFile/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Image Preview
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagePreviewApiImageDrawingFileFileIdPreviewGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getImagePreviewApiImageDrawingFileFileIdPreviewGet', 'fileId', fileId)
            const localVarPath = `/api/imageDrawingFile/{file_id}/preview`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Images By Drawing
         * @param {string} pdfId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet: async (pdfId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfId' is not null or undefined
            assertParamExists('getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet', 'pdfId', pdfId)
            const localVarPath = `/api/imageDrawingFile/by-drawing/{pdf_id}`
                .replace(`{${"pdf_id"}}`, encodeURIComponent(String(pdfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Image Drawing File
         * @param {string} name 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchImageDrawingFileApiImageDrawingFileSearchGet: async (name: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('searchImageDrawingFileApiImageDrawingFileSearchGet', 'name', name)
            const localVarPath = `/api/imageDrawingFile/search/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageDrawingFileApi - functional programming interface
 * @export
 */
export const ImageDrawingFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageDrawingFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.deleteImageDrawingFileApiImageDrawingFileFileIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Image Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllImageDrawingFilesApiImageDrawingFileGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageDrawingFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllImageDrawingFilesApiImageDrawingFileGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getAllImageDrawingFilesApiImageDrawingFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Image By Drawing And Number
         * @param {string} pdfId 
         * @param {number} imageNumber 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId: string, imageNumber: number, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDrawingFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId, imageNumber, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Image By Drawing And Number
         * @param {string} imageId 
         * @param {number} angle 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet(imageId: string, angle: number, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet(imageId, angle, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageDrawingFileApiImageDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDrawingFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageDrawingFileApiImageDrawingFileFileIdGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getImageDrawingFileApiImageDrawingFileFileIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Image Preview
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getImagePreviewApiImageDrawingFileFileIdPreviewGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Images By Drawing
         * @param {string} pdfId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageDrawingFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Search Image Drawing File
         * @param {string} name 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchImageDrawingFileApiImageDrawingFileSearchGet(name: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageDrawingFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchImageDrawingFileApiImageDrawingFileSearchGet(name, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.searchImageDrawingFileApiImageDrawingFileSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ImageDrawingFileApi - factory interface
 * @export
 */
export const ImageDrawingFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageDrawingFileApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Image Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImageDrawingFilesApiImageDrawingFileGet(tenantId?: string, options?: any): AxiosPromise<Array<ImageDrawingFileDto>> {
            return localVarFp.getAllImageDrawingFilesApiImageDrawingFileGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Image By Drawing And Number
         * @param {string} pdfId 
         * @param {number} imageNumber 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId: string, imageNumber: number, tenantId?: string, options?: any): AxiosPromise<ImageDrawingFileDto> {
            return localVarFp.getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId, imageNumber, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Image By Drawing And Number
         * @param {string} imageId 
         * @param {number} angle 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet(imageId: string, angle: number, tenantId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet(imageId, angle, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageDrawingFileApiImageDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<ImageDrawingFileDto> {
            return localVarFp.getImageDrawingFileApiImageDrawingFileFileIdGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Image Preview
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Images By Drawing
         * @param {string} pdfId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId: string, tenantId?: string, options?: any): AxiosPromise<Array<ImageDrawingFileDto>> {
            return localVarFp.getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Image Drawing File
         * @param {string} name 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchImageDrawingFileApiImageDrawingFileSearchGet(name: string, tenantId?: string, options?: any): AxiosPromise<Array<ImageDrawingFileDto>> {
            return localVarFp.searchImageDrawingFileApiImageDrawingFileSearchGet(name, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageDrawingFileApi - object-oriented interface
 * @export
 * @class ImageDrawingFileApi
 * @extends {BaseAPI}
 */
export class ImageDrawingFileApi extends BaseAPI {
    /**
     * 
     * @summary Delete Image Drawing File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Image Drawing Files
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getAllImageDrawingFilesApiImageDrawingFileGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getAllImageDrawingFilesApiImageDrawingFileGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Image By Drawing And Number
     * @param {string} pdfId 
     * @param {number} imageNumber 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId: string, imageNumber: number, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId, imageNumber, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Image By Drawing And Number
     * @param {string} imageId 
     * @param {number} angle 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet(imageId: string, angle: number, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getImageByDrawingAndNumberApiImageDrawingFileImageIdRotateGet(imageId, angle, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Image Drawing File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getImageDrawingFileApiImageDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getImageDrawingFileApiImageDrawingFileFileIdGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Image Preview
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Images By Drawing
     * @param {string} pdfId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Image Drawing File
     * @param {string} name 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public searchImageDrawingFileApiImageDrawingFileSearchGet(name: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).searchImageDrawingFileApiImageDrawingFileSearchGet(name, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoApiInfoGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/info/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoApiInfoGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoApiInfoGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InfoApi.getInfoApiInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoApiInfoGet(options?: any): AxiosPromise<InfoDto> {
            return localVarFp.getInfoApiInfoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @summary Get Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public getInfoApiInfoGet(options?: RawAxiosRequestConfig) {
        return InfoApiFp(this.configuration).getInfoApiInfoGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PdfDrawingFileApi - axios parameter creator
 * @export
 */
export const PdfDrawingFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePdfDrawingFileApiPdfDrawingFileFileIdDelete: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deletePdfDrawingFileApiPdfDrawingFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/pdfDrawingFile/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Pdf Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPdfDrawingFilesApiPdfDrawingFileGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pdfDrawingFile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Content With Labels
         * @param {string} pdfId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentWithLabelsApiPdfDrawingFilePdfWithLabelsGet: async (pdfId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfId' is not null or undefined
            assertParamExists('getContentWithLabelsApiPdfDrawingFilePdfWithLabelsGet', 'pdfId', pdfId)
            const localVarPath = `/api/pdfDrawingFile/pdf_with_labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (pdfId !== undefined) {
                localVarQueryParameter['pdf_id'] = pdfId;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfDrawingFileApiPdfDrawingFileFileIdGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getPdfDrawingFileApiPdfDrawingFileFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/pdfDrawingFile/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Pdf Drawing File
         * @param {string} name 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPdfDrawingFileApiPdfDrawingFileSearchGet: async (name: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('searchPdfDrawingFileApiPdfDrawingFileSearchGet', 'name', name)
            const localVarPath = `/api/pdfDrawingFile/search/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PdfDrawingFileApi - functional programming interface
 * @export
 */
export const PdfDrawingFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PdfDrawingFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PdfDrawingFileApi.deletePdfDrawingFileApiPdfDrawingFileFileIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Pdf Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PdfDrawingFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PdfDrawingFileApi.getAllPdfDrawingFilesApiPdfDrawingFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Content With Labels
         * @param {string} pdfId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentWithLabelsApiPdfDrawingFilePdfWithLabelsGet(pdfId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentWithLabelsApiPdfDrawingFilePdfWithLabelsGet(pdfId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PdfDrawingFileApi.getContentWithLabelsApiPdfDrawingFilePdfWithLabelsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfDrawingFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PdfDrawingFileApi.getPdfDrawingFileApiPdfDrawingFileFileIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Search Pdf Drawing File
         * @param {string} name 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPdfDrawingFileApiPdfDrawingFileSearchGet(name: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PdfDrawingFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPdfDrawingFileApiPdfDrawingFileSearchGet(name, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PdfDrawingFileApi.searchPdfDrawingFileApiPdfDrawingFileSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PdfDrawingFileApi - factory interface
 * @export
 */
export const PdfDrawingFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PdfDrawingFileApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Pdf Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId?: string, options?: any): AxiosPromise<Array<PdfDrawingFileDto>> {
            return localVarFp.getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Content With Labels
         * @param {string} pdfId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentWithLabelsApiPdfDrawingFilePdfWithLabelsGet(pdfId: string, tenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getContentWithLabelsApiPdfDrawingFilePdfWithLabelsGet(pdfId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<PdfDrawingFileDto> {
            return localVarFp.getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Pdf Drawing File
         * @param {string} name 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPdfDrawingFileApiPdfDrawingFileSearchGet(name: string, tenantId?: string, options?: any): AxiosPromise<Array<PdfDrawingFileDto>> {
            return localVarFp.searchPdfDrawingFileApiPdfDrawingFileSearchGet(name, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PdfDrawingFileApi - object-oriented interface
 * @export
 * @class PdfDrawingFileApi
 * @extends {BaseAPI}
 */
export class PdfDrawingFileApi extends BaseAPI {
    /**
     * 
     * @summary Delete Pdf Drawing File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfDrawingFileApi
     */
    public deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return PdfDrawingFileApiFp(this.configuration).deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Pdf Drawing Files
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfDrawingFileApi
     */
    public getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return PdfDrawingFileApiFp(this.configuration).getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Content With Labels
     * @param {string} pdfId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfDrawingFileApi
     */
    public getContentWithLabelsApiPdfDrawingFilePdfWithLabelsGet(pdfId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return PdfDrawingFileApiFp(this.configuration).getContentWithLabelsApiPdfDrawingFilePdfWithLabelsGet(pdfId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Pdf Drawing File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfDrawingFileApi
     */
    public getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return PdfDrawingFileApiFp(this.configuration).getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Pdf Drawing File
     * @param {string} name 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfDrawingFileApi
     */
    public searchPdfDrawingFileApiPdfDrawingFileSearchGet(name: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return PdfDrawingFileApiFp(this.configuration).searchPdfDrawingFileApiPdfDrawingFileSearchGet(name, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Attach Role To Project
         * @param {string} projectId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost: async (projectId: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost', 'projectId', projectId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost', 'roleId', roleId)
            const localVarPath = `/api/projects/{project_id}/attach/role/{role_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attach User To Project
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost: async (projectId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost', 'projectId', projectId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost', 'userId', userId)
            const localVarPath = `/api/projects/{project_id}/attach/user/{user_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Project
         * @param {string} projectName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectApiProjectsPost: async (projectName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createProjectApiProjectsPost', 'projectName', projectName)
            const localVarPath = `/api/projects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectApiProjectsProjectIdDelete: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProjectApiProjectsProjectIdDelete', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detach Role From Project
         * @param {string} projectId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost: async (projectId: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost', 'projectId', projectId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost', 'roleId', roleId)
            const localVarPath = `/api/projects/{project_id}/detach/role/{role_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detach User From Project
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost: async (projectId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost', 'projectId', projectId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost', 'userId', userId)
            const localVarPath = `/api/projects/{project_id}/detach/user/{user_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsApiProjectsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/projects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Project
         * @param {string} projectName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectApiProjectsProjectNameGet: async (projectName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getProjectApiProjectsProjectNameGet', 'projectName', projectName)
            const localVarPath = `/api/projects/{project_name}`
                .replace(`{${"project_name"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Projects By Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByRoleApiProjectsByRoleRoleIdGet: async (roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('getProjectsByRoleApiProjectsByRoleRoleIdGet', 'roleId', roleId)
            const localVarPath = `/api/projects/by-role/{role_id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Projects By User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByUserApiProjectsByUserUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getProjectsByUserApiProjectsByUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/projects/by-user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Roles By Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesByProjectApiProjectsRolesProjectProjectIdGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getRolesByProjectApiProjectsRolesProjectProjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/roles/project/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users By Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByProjectApiProjectsUsersProjectProjectIdGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getUsersByProjectApiProjectsUsersProjectProjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/users/project/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users By Roles
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByRolesApiProjectsByRolesGet: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getUsersByRolesApiProjectsByRolesGet', 'requestBody', requestBody)
            const localVarPath = `/api/projects/by-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Attach Role To Project
         * @param {string} projectId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost(projectId: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost(projectId, roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Attach User To Project
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost(projectId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost(projectId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Project
         * @param {string} projectName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectApiProjectsPost(projectName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectApiProjectsPost(projectName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createProjectApiProjectsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectApiProjectsProjectIdDelete(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectApiProjectsProjectIdDelete(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.deleteProjectApiProjectsProjectIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Detach Role From Project
         * @param {string} projectId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost(projectId: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost(projectId, roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Detach User From Project
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost(projectId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost(projectId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectsApiProjectsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProjectsApiProjectsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getAllProjectsApiProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Project
         * @param {string} projectName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectApiProjectsProjectNameGet(projectName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectApiProjectsProjectNameGet(projectName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectApiProjectsProjectNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Projects By Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByRoleApiProjectsByRoleRoleIdGet(roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByRoleApiProjectsByRoleRoleIdGet(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectsByRoleApiProjectsByRoleRoleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Projects By User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByUserApiProjectsByUserUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByUserApiProjectsByUserUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectsByUserApiProjectsByUserUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Roles By Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRolesByProjectApiProjectsRolesProjectProjectIdGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRolesByProjectApiProjectsRolesProjectProjectIdGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getRolesByProjectApiProjectsRolesProjectProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Users By Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByProjectApiProjectsUsersProjectProjectIdGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByProjectApiProjectsUsersProjectProjectIdGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getUsersByProjectApiProjectsUsersProjectProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Users By Roles
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByRolesApiProjectsByRolesGet(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByRolesApiProjectsByRolesGet(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getUsersByRolesApiProjectsByRolesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Attach Role To Project
         * @param {string} projectId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost(projectId: string, roleId: string, options?: any): AxiosPromise<any> {
            return localVarFp.attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost(projectId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attach User To Project
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost(projectId: string, userId: string, options?: any): AxiosPromise<any> {
            return localVarFp.attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost(projectId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Project
         * @param {string} projectName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectApiProjectsPost(projectName: string, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.createProjectApiProjectsPost(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectApiProjectsProjectIdDelete(projectId: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteProjectApiProjectsProjectIdDelete(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Detach Role From Project
         * @param {string} projectId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost(projectId: string, roleId: string, options?: any): AxiosPromise<any> {
            return localVarFp.detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost(projectId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Detach User From Project
         * @param {string} projectId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost(projectId: string, userId: string, options?: any): AxiosPromise<any> {
            return localVarFp.detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost(projectId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsApiProjectsGet(options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.getAllProjectsApiProjectsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Project
         * @param {string} projectName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectApiProjectsProjectNameGet(projectName: string, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.getProjectApiProjectsProjectNameGet(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Projects By Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByRoleApiProjectsByRoleRoleIdGet(roleId: string, options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.getProjectsByRoleApiProjectsByRoleRoleIdGet(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Projects By User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByUserApiProjectsByUserUserIdGet(userId: string, options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.getProjectsByUserApiProjectsByUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Roles By Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesByProjectApiProjectsRolesProjectProjectIdGet(projectId: string, options?: any): AxiosPromise<Array<RoleDto>> {
            return localVarFp.getRolesByProjectApiProjectsRolesProjectProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users By Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByProjectApiProjectsUsersProjectProjectIdGet(projectId: string, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getUsersByProjectApiProjectsUsersProjectProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users By Roles
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByRolesApiProjectsByRolesGet(requestBody: Array<string>, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getUsersByRolesApiProjectsByRolesGet(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Attach Role To Project
     * @param {string} projectId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost(projectId: string, roleId: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).attachRoleToProjectApiProjectsProjectIdAttachRoleRoleIdPost(projectId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attach User To Project
     * @param {string} projectId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost(projectId: string, userId: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).attachUserToProjectApiProjectsProjectIdAttachUserUserIdPost(projectId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Project
     * @param {string} projectName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProjectApiProjectsPost(projectName: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProjectApiProjectsPost(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Project
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProjectApiProjectsProjectIdDelete(projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProjectApiProjectsProjectIdDelete(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Detach Role From Project
     * @param {string} projectId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost(projectId: string, roleId: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).detachRoleFromProjectApiProjectsProjectIdDetachRoleRoleIdPost(projectId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Detach User From Project
     * @param {string} projectId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost(projectId: string, userId: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).detachUserFromProjectApiProjectsProjectIdDetachUserUserIdPost(projectId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getAllProjectsApiProjectsGet(options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getAllProjectsApiProjectsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Project
     * @param {string} projectName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectApiProjectsProjectNameGet(projectName: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectApiProjectsProjectNameGet(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Projects By Role
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectsByRoleApiProjectsByRoleRoleIdGet(roleId: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectsByRoleApiProjectsByRoleRoleIdGet(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Projects By User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectsByUserApiProjectsByUserUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectsByUserApiProjectsByUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Roles By Project
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getRolesByProjectApiProjectsRolesProjectProjectIdGet(projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getRolesByProjectApiProjectsRolesProjectProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users By Project
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUsersByProjectApiProjectsUsersProjectProjectIdGet(projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUsersByProjectApiProjectsUsersProjectProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users By Roles
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUsersByRolesApiProjectsByRolesGet(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUsersByRolesApiProjectsByRolesGet(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Role
         * @param {RoleDtoRequest} roleDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleApiRolesPost: async (roleDtoRequest: RoleDtoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleDtoRequest' is not null or undefined
            assertParamExists('createRoleApiRolesPost', 'roleDtoRequest', roleDtoRequest)
            const localVarPath = `/api/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesApiRolesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Role
         * @param {RoleDtoRequest} roleDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleApiRolesPost(roleDtoRequest: RoleDtoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoleApiRolesPost(roleDtoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.createRoleApiRolesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRolesApiRolesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRolesApiRolesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.getRolesApiRolesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Role
         * @param {RoleDtoRequest} roleDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleApiRolesPost(roleDtoRequest: RoleDtoRequest, options?: any): AxiosPromise<RoleDto> {
            return localVarFp.createRoleApiRolesPost(roleDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesApiRolesGet(options?: any): AxiosPromise<RoleDto> {
            return localVarFp.getRolesApiRolesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Create Role
     * @param {RoleDtoRequest} roleDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createRoleApiRolesPost(roleDtoRequest: RoleDtoRequest, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).createRoleApiRolesPost(roleDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRolesApiRolesGet(options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRolesApiRolesGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShapeApi - axios parameter creator
 * @export
 */
export const ShapeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Shape
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShapeApiShapePost: async (shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeDtoRequest' is not null or undefined
            assertParamExists('createShapeApiShapePost', 'shapeDtoRequest', shapeDtoRequest)
            const localVarPath = `/api/shape/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shapeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShapeApiShapeShapeIdDelete: async (shapeId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('deleteShapeApiShapeShapeIdDelete', 'shapeId', shapeId)
            const localVarPath = `/api/shape/{shape_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} imageDrawingId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapesApiShapeByImageDrawingImageDrawingIdGet: async (imageDrawingId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageDrawingId' is not null or undefined
            assertParamExists('getAllShapesApiShapeByImageDrawingImageDrawingIdGet', 'imageDrawingId', imageDrawingId)
            const localVarPath = `/api/shape/by-imageDrawing/{image_drawing_id}`
                .replace(`{${"image_drawing_id"}}`, encodeURIComponent(String(imageDrawingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapesApiShapeGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shape/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapeApiShapeShapeIdGet: async (shapeId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('getShapeApiShapeShapeIdGet', 'shapeId', shapeId)
            const localVarPath = `/api/shape/{shape_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link Shape With Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost: async (shapeId: string, connectionId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost', 'shapeId', shapeId)
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost', 'connectionId', connectionId)
            const localVarPath = `/api/shape/{shape_id}/attach/connection/{connection_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)))
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link Shape With Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost: async (shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost', 'shapeId', shapeId)
            // verify required parameter 'diagramComponentId' is not null or undefined
            assertParamExists('linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost', 'diagramComponentId', diagramComponentId)
            // verify required parameter 'dcType' is not null or undefined
            assertParamExists('linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost', 'dcType', dcType)
            const localVarPath = `/api/shape/{shape_id}/attach/diagram-component/{diagram_component_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)))
                .replace(`{${"diagram_component_id"}}`, encodeURIComponent(String(diagramComponentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (dcType !== undefined) {
                localVarQueryParameter['dc_type'] = dcType;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink Shape From Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete: async (shapeId: string, connectionId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete', 'shapeId', shapeId)
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete', 'connectionId', connectionId)
            const localVarPath = `/api/shape/{shape_id}/detach/connection/{connection_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)))
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink Shape From Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete: async (shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete', 'shapeId', shapeId)
            // verify required parameter 'diagramComponentId' is not null or undefined
            assertParamExists('unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete', 'diagramComponentId', diagramComponentId)
            // verify required parameter 'dcType' is not null or undefined
            assertParamExists('unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete', 'dcType', dcType)
            const localVarPath = `/api/shape/{shape_id}/detach/diagram-component/{diagram_component_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)))
                .replace(`{${"diagram_component_id"}}`, encodeURIComponent(String(diagramComponentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (dcType !== undefined) {
                localVarQueryParameter['dc_type'] = dcType;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Shape
         * @param {string} shapeId 
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShapeApiShapeShapeIdPut: async (shapeId: string, shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('updateShapeApiShapeShapeIdPut', 'shapeId', shapeId)
            // verify required parameter 'shapeDtoRequest' is not null or undefined
            assertParamExists('updateShapeApiShapeShapeIdPut', 'shapeDtoRequest', shapeDtoRequest)
            const localVarPath = `/api/shape/{shape_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shapeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShapeApi - functional programming interface
 * @export
 */
export const ShapeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShapeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Shape
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShapeApiShapePost(shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShapeApiShapePost(shapeDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.createShapeApiShapePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShapeApiShapeShapeIdDelete(shapeId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShapeApiShapeShapeIdDelete(shapeId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.deleteShapeApiShapeShapeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} imageDrawingId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShapeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.getAllShapesApiShapeByImageDrawingImageDrawingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShapesApiShapeGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShapesApiShapeGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.getAllShapesApiShapeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShapeApiShapeShapeIdGet(shapeId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShapeApiShapeShapeIdGet(shapeId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.getShapeApiShapeShapeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Link Shape With Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId: string, connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId, connectionId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Link Shape With Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId, diagramComponentId, dcType, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unlink Shape From Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId: string, connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId, connectionId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unlink Shape From Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId, diagramComponentId, dcType, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Shape
         * @param {string} shapeId 
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShapeApiShapeShapeIdPut(shapeId: string, shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShapeApiShapeShapeIdPut(shapeId, shapeDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.updateShapeApiShapeShapeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShapeApi - factory interface
 * @export
 */
export const ShapeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShapeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Shape
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShapeApiShapePost(shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: any): AxiosPromise<ShapeDto> {
            return localVarFp.createShapeApiShapePost(shapeDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShapeApiShapeShapeIdDelete(shapeId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteShapeApiShapeShapeIdDelete(shapeId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} imageDrawingId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId: string, tenantId?: string, options?: any): AxiosPromise<Array<ShapeDto>> {
            return localVarFp.getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapesApiShapeGet(tenantId?: string, options?: any): AxiosPromise<ShapeDtoResponse> {
            return localVarFp.getAllShapesApiShapeGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapeApiShapeShapeIdGet(shapeId: string, tenantId?: string, options?: any): AxiosPromise<ShapeDto> {
            return localVarFp.getShapeApiShapeShapeIdGet(shapeId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link Shape With Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId: string, connectionId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId, connectionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link Shape With Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId, diagramComponentId, dcType, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink Shape From Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId: string, connectionId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId, connectionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink Shape From Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId, diagramComponentId, dcType, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Shape
         * @param {string} shapeId 
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShapeApiShapeShapeIdPut(shapeId: string, shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: any): AxiosPromise<ShapeDto> {
            return localVarFp.updateShapeApiShapeShapeIdPut(shapeId, shapeDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShapeApi - object-oriented interface
 * @export
 * @class ShapeApi
 * @extends {BaseAPI}
 */
export class ShapeApi extends BaseAPI {
    /**
     * 
     * @summary Create Shape
     * @param {ShapeDtoRequest} shapeDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public createShapeApiShapePost(shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).createShapeApiShapePost(shapeDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Shape
     * @param {string} shapeId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public deleteShapeApiShapeShapeIdDelete(shapeId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).deleteShapeApiShapeShapeIdDelete(shapeId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Shapes
     * @param {string} imageDrawingId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Shapes
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public getAllShapesApiShapeGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).getAllShapesApiShapeGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Shape
     * @param {string} shapeId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public getShapeApiShapeShapeIdGet(shapeId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).getShapeApiShapeShapeIdGet(shapeId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link Shape With Connection
     * @param {string} shapeId 
     * @param {string} connectionId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId: string, connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId, connectionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link Shape With Diagram Component
     * @param {string} shapeId 
     * @param {string} diagramComponentId 
     * @param {DiagramComponentType} dcType 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId, diagramComponentId, dcType, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink Shape From Connection
     * @param {string} shapeId 
     * @param {string} connectionId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId: string, connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId, connectionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink Shape From Diagram Component
     * @param {string} shapeId 
     * @param {string} diagramComponentId 
     * @param {DiagramComponentType} dcType 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId, diagramComponentId, dcType, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Shape
     * @param {string} shapeId 
     * @param {ShapeDtoRequest} shapeDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public updateShapeApiShapeShapeIdPut(shapeId: string, shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).updateShapeApiShapeShapeIdPut(shapeId, shapeDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShapeTagApi - axios parameter creator
 * @export
 */
export const ShapeTagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Shape Tag
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShapeTagApiShapeTagPost: async (shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeTagDtoRequest' is not null or undefined
            assertParamExists('createShapeTagApiShapeTagPost', 'shapeTagDtoRequest', shapeTagDtoRequest)
            const localVarPath = `/api/shapeTag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shapeTagDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShapeTagApiShapeTagShapeTagIdDelete: async (shapeTagId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeTagId' is not null or undefined
            assertParamExists('deleteShapeTagApiShapeTagShapeTagIdDelete', 'shapeTagId', shapeTagId)
            const localVarPath = `/api/shapeTag/{shape_tag_id}`
                .replace(`{${"shape_tag_id"}}`, encodeURIComponent(String(shapeTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Shape Tags
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapeTagsApiShapeTagGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shapeTag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapeTagApiShapeTagShapeTagIdGet: async (shapeTagId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeTagId' is not null or undefined
            assertParamExists('getShapeTagApiShapeTagShapeTagIdGet', 'shapeTagId', shapeTagId)
            const localVarPath = `/api/shapeTag/{shape_tag_id}`
                .replace(`{${"shape_tag_id"}}`, encodeURIComponent(String(shapeTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Shape Tag
         * @param {string} shapeTagId 
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShapeTagApiShapeTagShapeTagIdPut: async (shapeTagId: string, shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeTagId' is not null or undefined
            assertParamExists('updateShapeTagApiShapeTagShapeTagIdPut', 'shapeTagId', shapeTagId)
            // verify required parameter 'shapeTagDtoRequest' is not null or undefined
            assertParamExists('updateShapeTagApiShapeTagShapeTagIdPut', 'shapeTagDtoRequest', shapeTagDtoRequest)
            const localVarPath = `/api/shapeTag/{shape_tag_id}`
                .replace(`{${"shape_tag_id"}}`, encodeURIComponent(String(shapeTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shapeTagDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShapeTagApi - functional programming interface
 * @export
 */
export const ShapeTagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShapeTagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Shape Tag
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShapeTagApiShapeTagPost(shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShapeTagApiShapeTagPost(shapeTagDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.createShapeTagApiShapeTagPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.deleteShapeTagApiShapeTagShapeTagIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Shape Tags
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShapeTagsApiShapeTagGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShapeTagDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShapeTagsApiShapeTagGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.getAllShapeTagsApiShapeTagGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShapeTagApiShapeTagShapeTagIdGet(shapeTagId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShapeTagApiShapeTagShapeTagIdGet(shapeTagId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.getShapeTagApiShapeTagShapeTagIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Shape Tag
         * @param {string} shapeTagId 
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId: string, shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId, shapeTagDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.updateShapeTagApiShapeTagShapeTagIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShapeTagApi - factory interface
 * @export
 */
export const ShapeTagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShapeTagApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Shape Tag
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShapeTagApiShapeTagPost(shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: any): AxiosPromise<ShapeTagDto> {
            return localVarFp.createShapeTagApiShapeTagPost(shapeTagDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Shape Tags
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapeTagsApiShapeTagGet(tenantId?: string, options?: any): AxiosPromise<Array<ShapeTagDto>> {
            return localVarFp.getAllShapeTagsApiShapeTagGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapeTagApiShapeTagShapeTagIdGet(shapeTagId: string, tenantId?: string, options?: any): AxiosPromise<ShapeTagDto> {
            return localVarFp.getShapeTagApiShapeTagShapeTagIdGet(shapeTagId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Shape Tag
         * @param {string} shapeTagId 
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId: string, shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: any): AxiosPromise<ShapeTagDto> {
            return localVarFp.updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId, shapeTagDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShapeTagApi - object-oriented interface
 * @export
 * @class ShapeTagApi
 * @extends {BaseAPI}
 */
export class ShapeTagApi extends BaseAPI {
    /**
     * 
     * @summary Create Shape Tag
     * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public createShapeTagApiShapeTagPost(shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).createShapeTagApiShapeTagPost(shapeTagDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Shape Tag
     * @param {string} shapeTagId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Shape Tags
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public getAllShapeTagsApiShapeTagGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).getAllShapeTagsApiShapeTagGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Shape Tag
     * @param {string} shapeTagId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public getShapeTagApiShapeTagShapeTagIdGet(shapeTagId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).getShapeTagApiShapeTagShapeTagIdGet(shapeTagId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Shape Tag
     * @param {string} shapeTagId 
     * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId: string, shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId, shapeTagDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SsoApi - axios parameter creator
 * @export
 */
export const SsoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Process login response from OIDC and return user info
         * @summary Sso Callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoCallbackApiSsoCallbackGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sso/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate login url and redirect
         * @summary Sso Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoLoginApiSsoLoginGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sso/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SsoApi - functional programming interface
 * @export
 */
export const SsoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SsoApiAxiosParamCreator(configuration)
    return {
        /**
         * Process login response from OIDC and return user info
         * @summary Sso Callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoCallbackApiSsoCallbackGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoCallbackApiSsoCallbackGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SsoApi.ssoCallbackApiSsoCallbackGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generate login url and redirect
         * @summary Sso Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoLoginApiSsoLoginGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoLoginApiSsoLoginGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SsoApi.ssoLoginApiSsoLoginGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SsoApi - factory interface
 * @export
 */
export const SsoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SsoApiFp(configuration)
    return {
        /**
         * Process login response from OIDC and return user info
         * @summary Sso Callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoCallbackApiSsoCallbackGet(options?: any): AxiosPromise<any> {
            return localVarFp.ssoCallbackApiSsoCallbackGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Generate login url and redirect
         * @summary Sso Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoLoginApiSsoLoginGet(options?: any): AxiosPromise<any> {
            return localVarFp.ssoLoginApiSsoLoginGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SsoApi - object-oriented interface
 * @export
 * @class SsoApi
 * @extends {BaseAPI}
 */
export class SsoApi extends BaseAPI {
    /**
     * Process login response from OIDC and return user info
     * @summary Sso Callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoCallbackApiSsoCallbackGet(options?: RawAxiosRequestConfig) {
        return SsoApiFp(this.configuration).ssoCallbackApiSsoCallbackGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate login url and redirect
     * @summary Sso Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoLoginApiSsoLoginGet(options?: RawAxiosRequestConfig) {
        return SsoApiFp(this.configuration).ssoLoginApiSsoLoginGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Attach Role To User
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost: async (userId: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost', 'userId', userId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost', 'roleId', roleId)
            const localVarPath = `/api/users/{user_id}/attach-role/{role_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User
         * @param {UserDtoRequest} userDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiUsersCreatePost: async (userDtoRequest: UserDtoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDtoRequest' is not null or undefined
            assertParamExists('createUserApiUsersCreatePost', 'userDtoRequest', userDtoRequest)
            const localVarPath = `/api/users/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApiUsersUserIdDelete: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserApiUsersUserIdDelete', 'userId', userId)
            const localVarPath = `/api/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detach Role From User
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost: async (userId: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost', 'userId', userId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost', 'roleId', roleId)
            const localVarPath = `/api/users/{user_id}/detach-role/{role_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersApiUsersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeApiUsersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Roles
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRolesApiUsersUserIdRolesGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserRolesApiUsersUserIdRolesGet', 'userId', userId)
            const localVarPath = `/api/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiUsersLoginPost: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginApiUsersLoginPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginApiUsersLoginPost', 'password', password)
            const localVarPath = `/api/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Attach Role To User
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost(userId: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost(userId, roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create User
         * @param {UserDtoRequest} userDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserApiUsersCreatePost(userDtoRequest: UserDtoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserApiUsersCreatePost(userDtoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUserApiUsersCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserApiUsersUserIdDelete(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserApiUsersUserIdDelete(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUserApiUsersUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Detach Role From User
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost(userId: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost(userId, roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsersApiUsersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsersApiUsersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getAllUsersApiUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeApiUsersMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeApiUsersMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getMeApiUsersMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get User Roles
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRolesApiUsersUserIdRolesGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRolesApiUsersUserIdRolesGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserRolesApiUsersUserIdRolesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginApiUsersLoginPost(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginApiUsersLoginPost(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.loginApiUsersLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Attach Role To User
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost(userId: string, roleId: string, options?: any): AxiosPromise<any> {
            return localVarFp.attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost(userId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User
         * @param {UserDtoRequest} userDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiUsersCreatePost(userDtoRequest: UserDtoRequest, options?: any): AxiosPromise<UserDto> {
            return localVarFp.createUserApiUsersCreatePost(userDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApiUsersUserIdDelete(userId: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteUserApiUsersUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Detach Role From User
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost(userId: string, roleId: string, options?: any): AxiosPromise<any> {
            return localVarFp.detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost(userId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersApiUsersGet(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getAllUsersApiUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeApiUsersMeGet(options?: any): AxiosPromise<UserDto> {
            return localVarFp.getMeApiUsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Roles
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRolesApiUsersUserIdRolesGet(userId: string, options?: any): AxiosPromise<Array<RoleDto>> {
            return localVarFp.getUserRolesApiUsersUserIdRolesGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiUsersLoginPost(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: any): AxiosPromise<any> {
            return localVarFp.loginApiUsersLoginPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Attach Role To User
     * @param {string} userId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost(userId: string, roleId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).attachRoleToUserApiUsersUserIdAttachRoleRoleIdPost(userId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User
     * @param {UserDtoRequest} userDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserApiUsersCreatePost(userDtoRequest: UserDtoRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserApiUsersCreatePost(userDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserApiUsersUserIdDelete(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserApiUsersUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Detach Role From User
     * @param {string} userId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost(userId: string, roleId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).detachRoleFromUserApiUsersUserIdDetachRoleRoleIdPost(userId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllUsersApiUsersGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAllUsersApiUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMeApiUsersMeGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getMeApiUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Roles
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserRolesApiUsersUserIdRolesGet(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserRolesApiUsersUserIdRolesGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {string} username 
     * @param {string} password 
     * @param {string | null} [grantType] 
     * @param {string} [scope] 
     * @param {string | null} [clientId] 
     * @param {string | null} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public loginApiUsersLoginPost(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).loginApiUsersLoginPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}



