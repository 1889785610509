import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import elementsReducer from './elements/elementsSlice'
import schemesReducer from './schemes/schemesSlice'
import projectsReducer from './projects/projectSlice'
import usersReducer from './users/usersSlice'

export const store = configureStore({
	reducer: {
		elements: elementsReducer,
		schemes: schemesReducer,
		projects: projectsReducer,
		users: usersReducer,
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
