import useWebSocket, { ReadyState } from 'react-use-websocket'
import { notification } from 'antd'
import { useEffect } from 'react'

interface ConnectionMessage {
	status: 'ok' | 'false'
}

interface WebSocketProps {
	readyState: ReadyState
	lastJsonMessage: ConnectionMessage
}

export const useIsConnectionStable = () => {
	const { readyState, lastJsonMessage }: WebSocketProps = useWebSocket(`/api/health_check/`, {
		shouldReconnect: () => true,
		reconnectInterval: 5000,
	})

	useEffect(() => {
		const notificationDisconnected = (): void => {
			notification.error({
				duration: 0,
				closeIcon: null,
				key: 'connectionError',
				message: 'Что-то пошло не так',
				description: 'Потеряно соединение с сервером',
			})
		}

		if (readyState === ReadyState.CLOSED || lastJsonMessage?.status === 'false') {
			notificationDisconnected()
		} else if (readyState === ReadyState.OPEN && lastJsonMessage?.status === 'ok') {
			notification.destroy('connectionError')
		}
	}, [readyState, lastJsonMessage])
}
