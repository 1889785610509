export const getRandomColor = () => {
	const letters = '0123456789ABCDEF'
	let color = '#'
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)]
	}
	return color
}

export const showTotal = (total: number) => {
	if (total === 0) {
		return 'Ничего не найдено'
	} else if (total === 1) {
		return '1 результат'
	} else if (total > 1 && total < 5) {
		return `${total} результата`
	} else {
		return `${total} результатов`
	}
}