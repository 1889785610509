import { ElementDto } from '@lib/src/generated/backend'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createElement, deleteElement, fetchElements, updateElement } from './elements.actions'

export interface ElementsState {
	list: ElementDto[]
	status: 'loading' | 'failed' | 'success'
}

const initialState: ElementsState = {
	list: [],
	status: 'loading',
}

export const elementsSlice = createSlice({
	name: 'elements',
	initialState,
	reducers: {
		addElement: (state, action) => {
			if (state.list.find(el => el.id === action.payload.id)) return

			state.list.push(action.payload)
		},
		updateLocalElement: (state, action: PayloadAction<ElementDto>) => {
			const elementIndex = state.list.findIndex(el => el.id === action.payload.id)

			state.list.splice(elementIndex, 1, action.payload)
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchElements.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(fetchElements.rejected, (state, action) => {
			state.status = 'failed'
		})
		builder.addCase(fetchElements.fulfilled, (state, action) => {
			state.status = 'success'
			state.list = action.payload!
		})

		builder.addCase(createElement.fulfilled, (state, action) => {
			const elementIndex = state.list.findIndex(el => el.id === 'temp')

			state.list.splice(elementIndex, 1, action.payload!)
		})
		builder.addCase(updateElement.fulfilled, (state, action) => {
			const elementIndex = state.list.findIndex(el => el.id === action.payload!.id)

			state.list.splice(elementIndex, 1, action.payload!)
		})
		builder.addCase(deleteElement.fulfilled, (state, action) => {
			state.list = state.list.filter(el => el.id !== action.payload)
		})
	},
})

export const {} = elementsSlice.actions

export default elementsSlice.reducer
