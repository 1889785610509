import axios from 'axios'
import { 
	ElementsApiFactory, 
	ElementTypesApiFactory, 
	UsersApiFactory, 
	RolesApiFactory,
	ProjectsApiFactory,
	InfoApiFactory
 } from '../lib/src/generated/backend'

 axios.interceptors.request.use(
	config => {
	  config.withCredentials = true
	  config.headers['tenant-id'] = localStorage.getItem('currentProject') || 'test'
  
	  return config
	},
	async error => await Promise.reject(error)
  )

export const loginApi = UsersApiFactory({
	isJsonMime(): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const elementTypesApi = ElementTypesApiFactory({
	isJsonMime(): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const elementApi = ElementsApiFactory({
  isJsonMime(): boolean {
    return false
  },
  basePath: window.location.origin,
})

export const projectsApi = ProjectsApiFactory({
  isJsonMime(): boolean {
    return false
  },
  basePath: window.location.origin,
})

export const rolesApi = RolesApiFactory({
  isJsonMime(): boolean {
    return false
  },
  basePath: window.location.origin,
})

export const serverApi = InfoApiFactory({
	isJsonMime(): boolean {
		return false
	},
	basePath: window.location.origin,
})
