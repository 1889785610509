import { ImageDrawingFileDto, PdfDrawingFileDto } from '@lib/src/generated/backend'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchImageList, fetchPdfList, getImagesByName, getPDFsByName } from './schemes.actions'

export interface SchemesState {
	pdfList: PdfDrawingFileDto[]
	imageList: ImageDrawingFileDto[]
	searchResult: PdfDrawingFileDto[] | ImageDrawingFileDto[]
	status: 'loading' | 'failed' | 'success'
}

const initialState: SchemesState = {
	pdfList: [],
	imageList: [],
	searchResult: [],
	status: 'loading',
}

export const schemesSlice = createSlice({
	name: 'schemes',
	initialState,
	reducers: {
		addPdfFile: (state, action: PayloadAction<PdfDrawingFileDto>) => {
			state.pdfList.push(action.payload)
		},
		deletePdfFile: (state, action: PayloadAction<string>) => {
			state.pdfList = state.pdfList.filter(pdf => pdf.id !== action.payload)
			state.searchResult = state.searchResult.filter(pdf => pdf?.id !== action.payload)
		},
		clearSearchResult: state => {
			state.searchResult = []
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchPdfList.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(fetchPdfList.fulfilled, (state, action) => {
			state.pdfList = action.payload!
			state.status = 'success'
		})
		builder.addCase(fetchPdfList.rejected, state => {
			state.status = 'failed'
		})

		builder.addCase(getPDFsByName.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(getPDFsByName.fulfilled, (state, action) => {
			state.searchResult = action.payload!
			state.status = 'success'
		})
		builder.addCase(getPDFsByName.rejected, state => {
			state.status = 'failed'
		})

		builder.addCase(fetchImageList.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(fetchImageList.fulfilled, (state, action) => {
			state.imageList = action.payload!
			state.status = 'success'
		})
		builder.addCase(fetchImageList.rejected, state => {
			state.status = 'failed'
		})
		builder.addCase(getImagesByName.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(getImagesByName.fulfilled, (state, action) => {
			state.searchResult = action.payload!
			state.status = 'success'
		})
		builder.addCase(getImagesByName.rejected, state => {
			state.status = 'failed'
		})
	},
})

export const {} = schemesSlice.actions

export default schemesSlice.reducer
