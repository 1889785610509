// extracted by mini-css-extract-plugin
var _1 = "breadCrumbs_a3df";
var _2 = "buttons_c30e";
var _3 = "content_a3c0";
var _4 = "header_ec7d";
var _5 = "logo_b1ef";
var _6 = "logoTitle_ebed";
var _7 = "tags_a697";
var _8 = "text_a909";
export { _1 as "breadCrumbs", _2 as "buttons", _3 as "content", _4 as "header", _5 as "logo", _6 as "logoTitle", _7 as "tags", _8 as "text" }
