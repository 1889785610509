import { bindActionCreators } from '@reduxjs/toolkit'
import { AppDispatch, elementsSlice, schemesSlice } from '@store'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

const allActions = {
	...elementsSlice.actions,
	...schemesSlice.actions,
}

export const useActions = () => {
	const dispatch = useDispatch<AppDispatch>()

	return useMemo(() => bindActionCreators(allActions, dispatch), [dispatch])
}
