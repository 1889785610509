import { createSlice } from '@reduxjs/toolkit'
import { attachUserToProject, detachUserFromProject, getAllUsers, getMe, getUsersForProject } from './users.actions'
import { UserDto } from '@lib/src/generated/backend'

export interface usersState {
	usersList: UserDto[]
	projectUsersList: UserDto[]
	currentUser: Omit<UserDto, 'auth_type'>
	status: 'loading' | 'failed' | 'success'
}

const initialState: usersState = {
	usersList: [],
	projectUsersList: [],
	currentUser: {
		login: '',
		id: '',
	},
	status: 'loading',
}

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {},
	extraReducers: builder => {
		// get me
		builder.addCase(getMe.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(getMe.fulfilled, (state, action) => {
			state.usersList.push(action.payload!)
			state.currentUser = action.payload!
			state.status = 'success'
		})
		builder.addCase(getMe.rejected, state => {
			state.status = 'failed'
		})

		// get all users
		builder.addCase(getAllUsers.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(getAllUsers.fulfilled, (state, action) => {
			state.usersList = action.payload!
			state.status = 'success'
		})
		builder.addCase(getAllUsers.rejected, state => {
			state.status = 'failed'
		})

		// get users for project
		builder.addCase(getUsersForProject.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(getUsersForProject.fulfilled, (state, action) => {
			state.projectUsersList = action.payload!
			state.status = 'success'
		})
		builder.addCase(getUsersForProject.rejected, (state, action) => {
			state.status = 'failed'
		})

		// attach user to project
		builder.addCase(attachUserToProject.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(attachUserToProject.fulfilled, (state, action) => {
			const user = state.usersList.find(user => user.id === action.payload)
			state.projectUsersList = [...state.projectUsersList, user!]
			state.status = 'success'
		})
		builder.addCase(attachUserToProject.rejected, (state, action) => {
			state.status = 'failed'
		})

		// detach user from project
		builder.addCase(detachUserFromProject.pending, state => {
			state.status = 'loading'
		})
		builder.addCase(detachUserFromProject.fulfilled, (state, action) => {
			state.projectUsersList = state.projectUsersList.filter(user => user.id !== action.payload)
			state.status = 'success'
		})
		builder.addCase(detachUserFromProject.rejected, (state, action) => {
			state.status = 'failed'
		})
	},
})
export const {} = usersSlice.actions

export const {} = usersSlice.actions

export default usersSlice.reducer
