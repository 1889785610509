import { createSlice, type PayloadAction, type Action } from '@reduxjs/toolkit'
import { ApplicationMode } from '../../../types'
import type { Vector2d } from 'konva/lib/types'
import { getAppData } from '../actions/common/getAppData'
import { TOOLS } from '../../components/header/tools/consts'
import { ZOOM_STEP } from '../../../consts'
import { type IShape } from './types'
import { finishDraw } from '../actions/shapes/shapeActions'
import type { ConnectionDto, DiagramComponent, ShapeDtoRequest, ShapeTagDto, ShapeType } from '../../generated/backend'
import type { DiagramComponentMapping } from '../actions/common/types'

export interface IShapeDraft extends ShapeDtoRequest {
	connections: ConnectionDto[]
	isDrawing?: boolean
}

export type AppShape = IShape<IInitialState['applicationMode']>

export interface IInitialState {
	image: {
		id: string | null
		loading: boolean
		error: boolean
		isRotateSuccess: boolean
	}
	imageLayer: {
		zoom: Vector2d
	}
	stage: {
		selectedTool: TOOLS
		zoom: Vector2d
		minimalZoom: Vector2d
		position: Vector2d
		draft: IShapeDraft | null
		tagDraft: {
			position: Vector2d | null
			relatedShapeId: string | null
			shapePosition: Vector2d | null
		}
		shapes: {
			lines: AppShape[]
			rectangles: AppShape[]
			connectors: ConnectionDto[]
			tags: ShapeTagDto[]
		}
		contextMenuPosition: Vector2d | null
		labelPosition: Vector2d | null
		selectedShapes: AppShape[]
		diagramComponents: DiagramComponentMapping<IInitialState['applicationMode']> | null
		hoveringDiagramComponent: string | null
		invisibleEntitiesId: string[]
		isDragEnabled: boolean
	}
	diagramComponents: DiagramComponentMapping<IInitialState['applicationMode']> | null
	applicationMode: ApplicationMode
	errors: string[]
	isUIBlocked: boolean
}

interface RejectedAction extends Action {
	error: Error
	payload: {
		error: {
			message: string
		}
	}
}

const isRejectedAction = (action: RejectedAction): action is RejectedAction => {
	return action.type.endsWith('rejected')
}

const initialState = {
	image: {
		id: null,
		loading: true,
		error: false,
		isRotateSuccess: false,
	},
	imageLayer: {
		zoom: {
			x: 1,
			y: 1,
		},
	},
	stage: {
		selectedTool: TOOLS.DEFAULT,
		zoom: { x: 1, y: 1 },
		minimalZoom: { x: 1, y: 1 },
		position: { x: 0, y: 0 },
		draft: null,
		tagDraft: {
			position: null,
			relatedShapeId: null,
			shapePosition: null,
		},
		shapes: {
			lines: [],
			rectangles: [],
			connectors: [],
			tags: [],
		},
		diagramComponents: null,
		contextMenuPosition: null,
		labelPosition: null,
		selectedShapes: [],
		hoveringDiagramComponent: null,
		invisibleEntitiesId: [],
		isDragEnabled: false,
	},
	diagramComponents: null,
	applicationMode: ApplicationMode.DEMO_APP,
	errors: [],
	isUIBlocked: false,
} satisfies IInitialState as IInitialState

const drawSlice = createSlice({
	name: 'draw',
	initialState,
	selectors: {
		selectIsRotateDisabled: state =>
			state.stage.shapes.lines.length > 0 ||
			state.stage.shapes.rectangles.length > 0 ||
			state.stage.shapes.connectors.length > 0 ||
			state.stage.shapes.tags.length > 0,
	},
	reducers: {
		setIsRotateSuccess: (state, action: PayloadAction<boolean>) => {
			state.image.isRotateSuccess = action.payload
		},
		setIsUIBlocked: (state, action: PayloadAction<boolean>) => {
			state.isUIBlocked = action.payload
		},
		setIsDragEnabled: (state, action: PayloadAction<boolean>) => {
			state.stage.isDragEnabled = action.payload
		},
		setApplicationMode: (state, action: PayloadAction<ApplicationMode>) => {
			state.applicationMode = action.payload
		},
		setImageId: (state, action: PayloadAction<string>) => {
			state.image.id = action.payload
		},
		setImageLoaded: (state, action: PayloadAction<{ loading: boolean; error: boolean }>) => {
			state.image.loading = action.payload.loading
			state.image.error = action.payload.error
		},
		setImageScale: (state, action: PayloadAction<Vector2d>) => {
			state.imageLayer.zoom = action.payload
			state.stage.minimalZoom = action.payload
		},
		setSelectedTool: (state, action: PayloadAction<TOOLS>) => {
			state.stage.selectedTool = action.payload
		},
		zoomIn: state => {
			state.stage.zoom = {
				x: state.stage.zoom.x + ZOOM_STEP,
				y: state.stage.zoom.y + ZOOM_STEP,
			}
		},
		zoomOut: state => {
			const newZoom = {
				x: state.stage.zoom.x - ZOOM_STEP,
				y: state.stage.zoom.x - ZOOM_STEP,
			}
			state.stage.zoom = {
				x: newZoom.x > state.stage.minimalZoom.x ? newZoom.x : state.stage.minimalZoom.x,
				y: newZoom.y > state.stage.minimalZoom.y ? newZoom.y : state.stage.minimalZoom.y,
			}
		},
		startDraw: (state, action: PayloadAction<IShapeDraft>) => {
			state.stage.draft = { ...action.payload, isDrawing: true }
		},
		drawingShape: (state, action: PayloadAction<Pick<IShapeDraft, 'width' | 'height'>>) => {
			if (state.stage.draft) {
				state.stage.draft.width = action.payload.width
				state.stage.draft.height = action.payload.height
			}
		},
		setSelectedShapes: <T extends IInitialState>(
			state: T,
			action: PayloadAction<Array<IShape<typeof state.applicationMode>>>
		) => {
			state.stage.selectedShapes = action.payload
		},
		setStageAttrs: (state, action: PayloadAction<{ scale: Vector2d; position: Vector2d }>) => {
			state.stage.zoom = action.payload.scale
			state.stage.position = action.payload.position
		},
		setContextMenuPosition: (state, action: PayloadAction<Vector2d | null>) => {
			state.stage.contextMenuPosition = action.payload
		},
		setLabelPosition: (state, action: PayloadAction<Vector2d | null>) => {
			state.stage.labelPosition = action.payload
		},
		setHoveringDiagramComponent: (state, action: PayloadAction<string | null>) => {
			state.stage.hoveringDiagramComponent = action.payload
		},
		setTagDraft: (
			state,
			action: PayloadAction<{
				position: Vector2d | null
				shapeId: string | null
				shapePosition: Vector2d | null
			}>
		) => {
			state.stage.tagDraft.position = action.payload.position
			state.stage.tagDraft.relatedShapeId = action.payload.shapeId
			state.stage.tagDraft.shapePosition = action.payload.shapePosition
		},
		addInvisibleId: (state, action: PayloadAction<string>) => {
			state.stage.invisibleEntitiesId = [...state.stage.invisibleEntitiesId, action.payload]
		},
		removeInvisibleId: (state, action: PayloadAction<string>) => {
			state.stage.invisibleEntitiesId = state.stage.invisibleEntitiesId.filter(id => id !== action.payload)
		},
		setStageDiagramComponents: (
			state,
			action: PayloadAction<DiagramComponentMapping<IInitialState['applicationMode']> | null>
		) => {
			state.stage.diagramComponents = action.payload
		},
		stopDraw: (state, action: PayloadAction<boolean | undefined>) => {
			state.stage.draft!.isDrawing = false
			if (action.payload) {
				state.stage.draft = null
			}
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getAppData.fulfilled, (state, action) => {
				state.stage.shapes.lines = action.payload.lines
				state.stage.shapes.rectangles = action.payload.rectangles
				state.stage.draft = null
				state.stage.shapes.connectors = action.payload.connectors
				state.diagramComponents = action.payload.diagramComponents
				state.stage.diagramComponents = action.payload.diagramComponentsOnDraw
				state.stage.shapes.tags = action.payload.tags
			})

			.addMatcher(isRejectedAction, (state, action) => {
				state.errors.push(action.payload.error?.message)
			})
	},
})

export default drawSlice.reducer

export const { selectIsRotateDisabled } = drawSlice.selectors

export const {
	setApplicationMode,
	setImageId,
	setImageLoaded,
	setImageScale,
	setSelectedTool,
	setStageAttrs,
	zoomIn,
	zoomOut,
	startDraw,
	drawingShape,
	setSelectedShapes,
	setContextMenuPosition,
	setHoveringDiagramComponent,
	setTagDraft,
	addInvisibleId,
	removeInvisibleId,
	setLabelPosition,
	setIsDragEnabled,
	setStageDiagramComponents,
	stopDraw,
	setIsUIBlocked,
	setIsRotateSuccess,
} = drawSlice.actions
